import React, {useContext, useEffect} from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import { useForm } from '../../hooks/useForms';
import { GlobalContext } from '../../providers/provides';
import { ValuationService } from '../../services/valuation';
import 'bootstrap/dist/css/bootstrap.css';
import Modal from 'react-bootstrap/Modal';
import './LeadPage.scss';

export default function LeadPage(props: any) {

  const valuationService: ValuationService = new ValuationService()
  const {valuationState} = useContext(GlobalContext);

  const [modalShow, setModalShow] = React.useState(false);

  const initialState = {
    name: "",
    email: "",
    phone: "",
    accepted: false
  };

  const { onChange, onSubmit, values } = useForm(leadCallback, initialState);

  // a submit function that will execute upon form submission
  async function leadCallback() {
    console.log('valuationCallback');
    console.log('values', values);

    const obj = {
      valuation: valuationState,
      lead: values
    };
    console.log(obj);

    const result = await valuationService.create(obj);

    props.history.push(`/3`, result);
  }

  function TermsOfUse(props : any) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <h4>Termos de uso</h4>
          <p>
            Texto a definir.
          </p>
        </Modal.Body>
        <Modal.Footer>
        <div className='footer-button'>
          <div className='container-footer-action'>
            <button type="submit" onClick={props.onHide}>Fechar</button>
          </div>
        </div>  
      </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <div className='container-flex'>
      <TermsOfUse
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
        <div className='contents'>
          <div className='container-progress'>
            <div className="progress">
                <ProgressBar now={99}/>
            </div>
            <span>3/3</span>
          </div>

          <div style={{marginTop: '30px'}}></div>
          <span className='sub-title-avaliacao'>Informações de contato</span>


          <form onSubmit={onSubmit} className="form-container">

            <div className='input-select'>
              <label>Nome e sobrenome</label>
              <input type="text" className="form-control" placeholder='Ex: João Santos' id="name" name="name" required onChange={onChange}></input>
            </div>

            <div className='input-select'>
              <label>E-mail</label>
              <input type="text" className="form-control" placeholder='exemplo@truliv.com' id="email" required name="email" onChange={onChange}></input>
            </div>

            <div className='input-select'>
              <label>Whatsapp</label>
              <InputMask
                  placeholder="(99) 99999-9999"
                  id="phone" name="phone"
                  className="form-control"
                  onChange={onChange}
                  required
                  mask={values.phone.length <= 10 ? '(99) 99999-9999' : '(99) 99999-9999'}
                // formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                />
            </div>

            {/* <div style={{marginTop: '30px'}}>
              <label className="container-label">
                
                <input type="checkbox" />

                <span className="checkmark"></span>
                &nbsp;Li e aceito os <button className='button-link' onClick={() => setModalShow(true)}>Termos de Uso</button>

              </label>
            </div>   */}

            <div className='footer-button'>
              <div className='container-footer-action'>
                <Link to="/1"><button className='button-back'>Voltar</button></Link>
                <Link to={{ pathname: '/3', state: { fromNotifications: true } }}></Link>
                {/* Se o botão submit sair do form não tem submit do form referente */}
                <button type="submit">Avaliar imóvel</button>
              </div>
            </div>  
          </form>

        </div>
        {/* FOOTER por hora para uma solução rapida */}
        <div className='footer-button' style={{position: 'absolute', bottom: '0px', zIndex: '-1'}}></div>
      </div>
      
    </>
  )
}

