import { BrowserRouter, Route, Switch } from "react-router-dom";
import EvaluationPage from "./pages/Evaluation/EvaluationPage";
import LeadPage from "./pages/Lead/LeadPage";
import ResultPage from "./pages/Result/ResultPage";
import ScrollToTop from './components/ScrollToTop';
import ImmobilePage from "./pages/Immobile/ImmobilePage";

function Routes() {
  return (
    <BrowserRouter>
      <ScrollToTop/>
      <Switch>
        <Route path="/" exact component={EvaluationPage} /> 
        <Route path="/1" component={ImmobilePage} /> 
        <Route path="/2" component={LeadPage} />
        <Route path="/3" component={ResultPage} />
        
        
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
