const production = true;
// teste local
// const production = fasle;

const environment = {
  production: (production ? true : false),
  apiUrl: (production ? 'https://truliv-api-4h568.ondigitalocean.app/api' : 'http://localhost:3000/api'),
  baseUrl: (production ? 'https://truliv-api-4h568.ondigitalocean.app' : 'http://localhost:3000'),
  appUrl: (production ? 'https://truliv.com.br/' : 'http://localhost:4200'),
};

export default environment;