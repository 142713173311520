import { PDFMaker } from '../../commons/PDFMaker';
import { UtilHelper } from '../../commons/UtilHellper';
import { Valuation } from '../../models/valuation.entity';
import AlternativeFooter from '../../components/AlternativeFooter/AlternativeFooter';
import './ResultPage.scss';
import { useEffect, useState } from 'react';
import environment from '../../environment';


export default function ResultPage(props: any) {

  const { state } = props.location;
  const result: Valuation = state;

  const [propertyPattern, setPropertyPattern] = useState<string>()
  useEffect(()=>{
    if(result.propertyPattern === 1) setPropertyPattern('Padrão Baixo');
    if (result.propertyPattern === 2) setPropertyPattern('Padrão Médio');
    if (result.propertyPattern === 3) setPropertyPattern('Padrão Alto')
  },[result]);

  const [condoPattern, setCondoPattern] = useState<string>()
  useEffect(()=>{
    if(result.condoPattern === 1) setCondoPattern('Padrão Baixo');
    if (result.condoPattern === 2) setCondoPattern('Padrão Médio');
    if (result.condoPattern === 3) setCondoPattern('Padrão Alto')
  },[result]);

  const [goal, setGoal] = useState<string>()
  useEffect(()=>{
    if(result.goal === 'BUY') setGoal('Comprar');
    if (result.goal === 'SELL') setGoal('Vender');
    
  },[result]);

  const util: UtilHelper = new UtilHelper();

  console.log('result', result);

  const generatePDF = () => {
    new PDFMaker().generatePDF(result);
  }

  return (
    <>
       <div className='container-flex'>
        <div className='contents'>
        
          <div className='header'>
            <span>Resultado</span> 
          </div>

          <h5 style={{marginTop: '40px', marginBottom: '30px', color: '#155859', fontWeight: 300}}>Características do imóvel avaliado</h5>
          <div className='result-info'>
            <p className='text'><span className='title'>Local:</span> {result?.neighborhood}, {result?.city} - {result?.state}</p>
            <p className='text'><span className='title'>Características: </span> {result?.type} de Apartamento de {result?.rooms} quarto(s), {result?.suites} suíte(s), {result?.garages} vaga(s), {result?.usefulArea}m² de área útil e condomínio de R$ {result?.condoPrice}</p>
            <p className='text'><span className='title'>Padrão do apartamento:</span>  {propertyPattern}</p>
            <p className='text'><span className='title'>Padrão do condomínio:</span> {condoPattern}</p>
          </div>

          <div className='container-result'>
            <div className='image'>
              <svg width="240" height="167" viewBox="0 0 240 167" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="83.5" cy="75.5" r="58.5" fill="white"/>
                <path d="M139.325 161.856C182.546 161.856 217.584 159.537 217.584 156.678C217.584 153.818 182.546 151.5 139.325 151.5C96.1033 151.5 61.0654 153.818 61.0654 156.678C61.0654 159.537 96.1033 161.856 139.325 161.856Z" fill="#EBEBEB"/>
                <path d="M116.492 62.6922C124.613 71.1382 149.012 108.728 150.912 109.851C150.912 109.851 150.912 134.435 137.324 129.526C123.735 124.617 111.279 80.5103 108.62 66.4497C107.218 59.0259 109.858 55.8011 116.492 62.6922Z" fill="#ECEBBE"/>
                <path d="M114.422 91.754L139.325 88.9227C139.325 88.9227 123.878 68.4364 115.896 61.1229C107.573 53.5024 103.493 54.5725 107.352 69.0075C111.23 83.5288 114.422 91.754 114.422 91.754Z" fill="#FA5064"/>
                <path d="M115.618 87.2768C116.199 87.1424 116.808 87.0897 117.399 87.0081L119.232 86.7537C120.466 86.581 121.704 86.4274 122.943 86.2739C125.419 85.9859 127.896 85.6836 130.378 85.4389C131.059 85.3717 131.746 85.3045 132.427 85.2517C133.109 85.1989 133.867 85.1989 134.587 85.1461C134.631 85.1461 134.631 85.2085 134.587 85.2133C133.978 85.3045 133.373 85.4437 132.763 85.5396C132.154 85.6356 131.506 85.722 130.877 85.7988C129.643 85.9571 128.4 86.1011 127.162 86.2403C124.723 86.5042 122.28 86.7825 119.832 86.9985L117.787 87.1712C117.072 87.2336 116.347 87.3392 115.627 87.3344C115.622 87.3324 115.617 87.3289 115.613 87.3243C115.609 87.3197 115.607 87.3143 115.606 87.3084C115.605 87.3026 115.606 87.2966 115.608 87.291C115.61 87.2855 115.613 87.2806 115.618 87.2768Z" fill="#263238"/>
                <path opacity="0.1" d="M126.504 90.3815L114.427 91.754C114.427 91.754 111.235 83.5288 107.352 69.0075C105.715 62.8841 105.508 59.165 106.449 57.4902L126.504 90.3815Z" fill="black"/>
                <path d="M121.329 142.958H83.4525C83.1405 141.739 82.8381 140.558 82.5357 139.359C82.1901 138.001 81.8493 136.676 81.5229 135.385C81.4509 135.097 81.3741 134.809 81.3021 134.526C81.0525 133.538 80.8077 132.578 80.5677 131.647C80.4237 131.076 80.2797 130.514 80.1405 129.958C80.0013 129.401 79.8525 128.835 79.7133 128.293C79.6749 128.149 79.6413 128.005 79.6029 127.865L79.4877 127.386L79.3437 126.824C79.2909 126.627 79.2429 126.435 79.1949 126.243C79.0989 125.879 79.0077 125.519 78.9213 125.168C78.7869 124.65 78.6573 124.142 78.5325 123.647C78.4797 123.431 78.4221 123.215 78.3693 123.004C78.2781 122.659 78.1917 122.323 78.1101 121.992C78.0525 121.771 77.9997 121.555 77.9469 121.344C77.7229 120.474 77.5149 119.658 77.3229 118.896C77.2701 118.695 77.2221 118.498 77.1693 118.306C77.0877 117.975 77.0061 117.658 76.9293 117.346C76.8749 117.154 76.8269 116.967 76.7853 116.785C76.6173 116.132 76.4637 115.537 76.3341 115.014C76.2957 114.875 76.2621 114.741 76.2285 114.611C76.1421 114.265 76.0605 113.958 75.9933 113.685C75.9645 113.574 75.9405 113.469 75.9117 113.373L75.8349 113.071C75.8349 113.003 75.8013 112.936 75.7869 112.879L75.7197 112.619C75.7197 112.578 75.7101 112.539 75.6909 112.504L75.6045 112.164H112.473C112.473 112.164 113.688 116.348 115.632 123.095C116.025 124.468 116.453 125.975 116.909 127.525C118.2 132.026 119.707 137.276 121.329 142.958Z" fill="#37474F"/>
                <path d="M89.6543 142.958H88.6943C87.2927 137.157 85.9343 131.346 84.6191 125.523C84.2991 124.106 83.9791 122.689 83.6591 121.272L83.5727 120.873C83.571 120.86 83.5745 120.846 83.5823 120.834C83.5902 120.823 83.6019 120.814 83.6153 120.811C83.6288 120.807 83.643 120.809 83.6555 120.815C83.6679 120.821 83.6777 120.832 83.6831 120.845C83.6831 120.888 83.7071 120.931 83.7215 120.974C84.1471 122.497 84.5711 124.021 84.9935 125.547C86.6159 131.335 88.1695 137.138 89.6543 142.958Z" fill="#263238"/>
                <path d="M111.278 112.168L109.651 120.326L108.317 127.006L106.046 138.389L105.811 139.555L105.422 141.499L105.12 142.958H83.4477C83.1405 141.734 82.8333 140.558 82.5357 139.359C82.1901 138.001 81.8493 136.676 81.5229 135.385C81.4509 135.097 81.3741 134.809 81.3021 134.526C81.0525 133.538 80.8077 132.578 80.5677 131.647C80.4237 131.076 80.2797 130.514 80.1405 129.958C80.0013 129.401 79.8525 128.835 79.7133 128.293C79.6749 128.149 79.6413 128.005 79.6029 127.865L79.4877 127.386L79.3437 126.824C79.2909 126.627 79.2429 126.435 79.1949 126.243C79.0989 125.879 79.0077 125.519 78.9213 125.168C78.7869 124.65 78.6573 124.142 78.5325 123.647C78.4749 123.426 78.4221 123.215 78.3693 123.004C78.2781 122.659 78.1917 122.323 78.1101 121.992C78.0525 121.771 77.9997 121.555 77.9469 121.344C77.7229 120.474 77.5149 119.658 77.3229 118.896C77.2701 118.695 77.2221 118.498 77.1693 118.306C77.0877 117.975 77.0061 117.658 76.9293 117.346C76.8749 117.154 76.8269 116.967 76.7853 116.785C76.6173 116.132 76.4637 115.537 76.3341 115.014C76.2957 114.875 76.2621 114.741 76.2285 114.611C76.1421 114.265 76.0605 113.958 75.9933 113.685C75.9645 113.574 75.9405 113.469 75.9117 113.373L75.8349 113.071C75.8349 113.003 75.8013 112.936 75.7869 112.879L75.7197 112.619C75.7197 112.578 75.7101 112.539 75.6909 112.504L75.6045 112.164L111.278 112.168Z" fill="#263238"/>
                <path d="M111.283 112.154C111.283 112.355 111.129 114.553 110.774 117.913C110.673 118.872 110.553 119.966 110.414 121.118C110.198 122.937 109.934 124.957 109.598 127.064C109.032 130.764 108.293 134.8 107.333 138.744C106.992 140.184 106.617 141.571 106.219 142.948H58.627C59.1886 135.697 59.7022 129.137 60.1102 123.969C60.6814 116.694 61.0702 112.14 61.0702 112.14H104.241L111.283 112.154Z" fill="#37474F"/>
                <path d="M111.183 116.526C111.13 118.229 111.058 119.928 110.947 121.622C110.832 123.46 110.664 125.293 110.424 127.126C110.424 127.193 110.424 127.261 110.4 127.328C110.176 129.046 109.919 130.759 109.627 132.467C109.354 134.061 109.147 135.779 108.447 137.266C108.18 137.837 107.802 138.348 107.333 138.768C106.904 139.16 106.379 139.432 105.811 139.555C105.718 139.579 105.624 139.595 105.528 139.603H105.447C105.464 139.613 105.478 139.628 105.488 139.646C105.497 139.664 105.501 139.684 105.499 139.704C105.499 140.275 105.499 140.856 105.432 141.422C105.432 141.446 105.432 141.47 105.432 141.494C105.375 141.974 105.283 142.478 105.192 142.967H104.376V142.938C104.52 142.401 104.64 141.859 104.775 141.316C104.909 140.774 105.101 140.222 105.231 139.661C105.238 139.64 105.251 139.621 105.269 139.608C104.751 139.633 104.238 139.499 103.799 139.223C103.359 138.948 103.015 138.544 102.811 138.068C102.607 137.338 102.593 136.568 102.773 135.831C102.922 134.872 103.066 133.883 103.215 132.909C103.493 131.119 103.853 129.348 104.175 127.568L104.319 126.757C104.957 123.134 105.624 119.51 106.426 115.921C106.455 115.772 106.488 115.623 106.522 115.475C106.555 115.326 106.704 115.403 106.695 115.499C106.671 115.739 106.647 115.978 106.618 116.223C106.477 117.602 106.317 118.975 106.138 120.341C105.907 121.948 105.749 123.565 105.523 125.173C105.447 125.72 105.37 126.267 105.288 126.814C104.919 129.458 104.535 132.093 104.083 134.737C103.872 135.961 103.123 138.154 104.871 138.533C105.274 138.623 105.696 138.57 106.066 138.384C106.844 137.916 107.425 137.181 107.703 136.316C108.135 134.876 108.448 133.402 108.639 131.911C108.927 130.311 109.183 128.697 109.407 127.069C109.695 125.05 109.995 123.033 110.309 121.017C110.466 119.99 110.626 118.963 110.789 117.936L111.019 116.497C111.024 116.396 111.183 116.425 111.183 116.526Z" fill="#263238"/>
                <path d="M69.0671 129.175C71.0491 129.155 73.021 128.889 74.9376 128.384C75.8208 128.158 76.6944 127.875 77.5632 127.592C78.4239 127.354 79.2296 126.95 79.9344 126.402C81.2688 125.25 81.888 123.441 82.4688 121.828C83.0993 120.013 83.6107 118.158 84 116.276C84 116.185 84.1536 116.219 84.1584 116.305C84.2045 118.433 83.9343 120.557 83.3568 122.606C82.8768 124.348 82.2336 126.287 80.8272 127.505C79.4208 128.724 77.3424 129.243 75.4464 129.473C73.3204 129.72 71.1713 129.691 69.0527 129.387C69.0247 129.385 68.9986 129.372 68.9802 129.351C68.9617 129.329 68.9524 129.302 68.9543 129.274C68.9563 129.246 68.9692 129.22 68.9904 129.201C69.0115 129.183 69.0391 129.174 69.0671 129.175Z" fill="#263238"/>
                <path d="M65.7602 116.334C65.6546 118.995 65.5426 121.66 65.4242 124.329C65.1522 130.538 64.853 136.748 64.5266 142.958H63.6338C64.0434 136.735 64.4738 130.516 64.925 124.3C65.1202 121.641 65.3218 118.984 65.5298 116.329C65.5304 116.298 65.5432 116.269 65.5652 116.248C65.5873 116.227 65.6168 116.215 65.6474 116.216C65.6779 116.217 65.707 116.229 65.7281 116.252C65.7493 116.274 65.7608 116.303 65.7602 116.334Z" fill="#263238"/>
                <path d="M119.477 131.863L54.9121 126.968C54.9121 126.968 67.9009 75.5003 93.5473 56.5784C100.104 51.746 107.28 54.8461 110.511 58.4596C118.719 67.6638 119.669 131.656 119.477 131.863Z" fill="#FA5064"/>
                <path d="M106.853 69.2139C103.128 68.662 99.3454 61.147 98.6926 56.0218C98.6494 55.6667 104.453 44.5957 105.36 42.2875C105.547 41.8076 118.488 51.5781 118.488 51.5781C118.488 51.5781 115.128 55.8587 113.558 59.9041C113.448 60.24 110.453 69.7417 106.853 69.2139Z" fill="#ECEBBE"/>
                <path d="M118.507 51.5781C118.507 51.5781 115.147 55.8587 113.573 59.8993C113.544 59.9809 113.352 60.576 113.035 61.4254C106.383 58.0662 103.671 52.0676 102.576 48.1085C103.834 45.5892 105.005 43.2233 105.379 42.2875C105.567 41.8125 118.507 51.5781 118.507 51.5781Z" fill="#263238"/>
                <path d="M108.351 24.3063C104.563 28.6876 100.776 44.4902 102.941 49.3467C106.085 56.3818 114.461 60.6096 121.239 56.1131C127.829 51.7509 132.399 31.0679 129.677 26.3266C125.674 19.3395 113.938 17.8374 108.351 24.3063Z" fill="#ECEBBE"/>
                <path d="M118.469 37.143C117.989 37.1814 117.509 37.143 117.029 37.143C116.52 37.2124 116.002 37.1112 115.556 36.8551C115.431 36.7471 115.346 36.6011 115.313 36.4398C115.28 36.2784 115.301 36.1107 115.373 35.9625C115.561 35.6919 115.817 35.4763 116.116 35.3379C116.414 35.1995 116.745 35.1435 117.072 35.1755C117.746 35.1359 118.409 35.348 118.935 35.7706C119.046 35.8753 119.125 36.0097 119.162 36.1579C119.199 36.3061 119.192 36.4618 119.143 36.6064C119.094 36.751 119.005 36.8785 118.885 36.9736C118.766 37.0688 118.621 37.1276 118.469 37.143Z" fill="#263238"/>
                <path d="M118.046 39.461C118.046 39.4178 117.95 39.4946 117.941 39.5425C117.763 40.6223 117.374 41.8316 116.333 41.9948C116.299 41.9948 116.289 42.0523 116.333 42.0571C117.547 42.1243 118.046 40.5167 118.046 39.461Z" fill="#263238"/>
                <path d="M117.307 38.1461C115.603 37.6662 114.778 41.1166 116.347 41.5533C117.917 41.99 118.733 38.5444 117.307 38.1461Z" fill="#263238"/>
                <path d="M124.238 40.7902C124.238 40.7518 124.31 40.8622 124.301 40.9054C124.013 41.9611 123.859 43.2232 124.738 43.7847C124.766 43.7847 124.738 43.8567 124.714 43.8423C123.576 43.42 123.797 41.75 124.238 40.7902Z" fill="#263238"/>
                <path d="M125.458 39.9073C127.205 40.1856 126.519 43.6552 124.901 43.3961C123.283 43.1369 123.994 39.6722 125.458 39.9073Z" fill="#263238"/>
                <path d="M127.229 38.1988C126.788 37.9939 126.359 37.7648 125.943 37.5125C125.455 37.3323 125.041 36.9946 124.767 36.5528C124.707 36.3983 124.7 36.2284 124.746 36.0694C124.793 35.9105 124.892 35.7716 125.026 35.6746C125.616 35.2907 126.298 35.4682 126.903 35.7658C127.519 36.0465 128.01 36.5457 128.28 37.167C128.337 37.3131 128.35 37.4727 128.317 37.6259C128.284 37.7791 128.207 37.9193 128.095 38.0291C127.983 38.1389 127.841 38.2135 127.687 38.2437C127.534 38.2738 127.374 38.2582 127.229 38.1988Z" fill="#263238"/>
                <path d="M114.475 48.6988C114.667 49.0107 114.84 49.4138 115.219 49.5386C115.62 49.6249 116.034 49.6249 116.434 49.5386C116.442 49.5378 116.45 49.5396 116.457 49.5438C116.464 49.548 116.469 49.5542 116.473 49.5617C116.476 49.5692 116.477 49.5774 116.475 49.5854C116.473 49.5933 116.469 49.6004 116.463 49.6058C116.261 49.7784 116.016 49.8931 115.754 49.9379C115.492 49.9827 115.222 49.9558 114.975 49.8601C114.77 49.7524 114.603 49.5862 114.493 49.3828C114.384 49.1793 114.337 48.9479 114.36 48.718C114.36 48.6556 114.447 48.6508 114.475 48.6988Z" fill="#263238"/>
                <path d="M116.357 45.7764C116.528 46.2623 116.797 46.7079 117.148 47.0852C117.499 47.4625 117.924 47.7635 118.397 47.9694C118.807 48.1547 119.242 48.2807 119.688 48.3437C119.771 48.3625 119.857 48.3722 119.942 48.3725C120.024 48.3777 120.106 48.3777 120.187 48.3725C120.246 48.3761 120.305 48.3596 120.354 48.3257C120.403 48.2917 120.439 48.2423 120.456 48.1854C120.458 48.147 120.458 48.1086 120.456 48.0702V47.9742C120.672 47.1584 120.893 45.8915 120.893 45.8915C121.157 46.1123 122.52 47.168 122.602 46.7313C123.24 43.1322 123.946 39.1299 123.821 35.4108C123.821 35.3841 123.81 35.3585 123.791 35.3396C123.772 35.3207 123.747 35.3101 123.72 35.3101C123.693 35.3101 123.668 35.3207 123.649 35.3396C123.63 35.3585 123.619 35.3841 123.619 35.4108C122.87 38.8468 122.731 42.3932 122.03 45.8579C121.561 45.4723 121.039 45.1556 120.48 44.9174C120.355 44.9174 120.101 47.3744 119.971 47.7295C119.972 47.7423 119.972 47.7551 119.971 47.7679C118.531 47.6143 117.61 46.9473 116.491 45.7044C116.419 45.6228 116.314 45.6756 116.357 45.7764Z" fill="#263238"/>
                <path d="M119.304 47.8109C118.713 48.288 118.009 48.6052 117.26 48.7323C116.849 48.7894 116.432 48.7173 116.064 48.526C115.268 48.0989 115.431 47.3119 115.748 46.6784C115.92 46.3445 116.131 46.0321 116.376 45.7474C117.107 46.7304 118.133 47.4536 119.304 47.8109Z" fill="#263238"/>
                <path d="M117.26 48.7323C116.849 48.7893 116.432 48.7172 116.064 48.5259C115.268 48.0988 115.431 47.3118 115.748 46.6783C116.64 46.9903 117.394 47.8301 117.26 48.7323Z" fill="#FF97B8"/>
                <path d="M131.127 32.4787C124.407 35.2908 117.61 27.5119 117.61 27.5119C116.84 28.6925 115.709 29.5928 114.386 30.0788C113.062 30.5647 111.617 30.6104 110.266 30.2088C110.266 30.2088 107.04 39.9361 104.41 39.3506C100.412 38.4964 102.01 24.6469 103.82 22.2379C105.629 19.8289 109.181 19.8385 109.181 19.8385C109.181 19.8385 109.858 17.8182 112.728 16.4409C115.896 14.9149 124.728 19.6993 124.728 19.6993C125.53 18.5499 126.591 17.6048 127.824 16.94C128.122 16.7726 128.451 16.6675 128.791 16.6309C129.131 16.5943 129.474 16.627 129.801 16.727C130.128 16.8271 130.431 16.9924 130.692 17.2129C130.952 17.4335 131.166 17.7047 131.319 18.0101C130.663 18.6407 130.159 19.412 129.845 20.2656C128.765 23.0873 131.151 23.5816 131.684 20.9278C131.915 19.943 131.786 18.9077 131.319 18.0101C132.855 16.5225 134.895 16.3593 135.869 17.5303C137.66 19.6753 136.719 30.1464 131.127 32.4787Z" fill="#263238"/>
                <path d="M114.643 44.802C113.816 44.537 113.08 44.0451 112.519 43.3825C111.958 42.7199 111.595 41.9131 111.47 41.054C111.345 40.1949 111.464 39.318 111.813 38.5232C112.163 37.7284 112.728 37.0476 113.445 36.5584C114.163 36.0691 115.003 35.791 115.871 35.7558C116.738 35.7205 117.598 35.9296 118.353 36.3591C119.107 36.7886 119.726 37.4213 120.139 38.1852C120.551 38.9491 120.741 39.8134 120.686 40.6798C120.641 41.3834 120.436 42.0674 120.086 42.6798C119.737 43.2923 119.253 43.8171 118.67 44.2145C118.088 44.6118 117.422 44.8712 116.725 44.9731C116.027 45.0749 115.315 45.0164 114.643 44.802ZM117.293 36.572C116.981 36.4712 116.659 36.4084 116.333 36.3848C115.31 36.3122 114.297 36.6327 113.502 37.2807C112.708 37.9288 112.19 38.8556 112.055 39.8722C111.921 40.8887 112.179 41.9183 112.778 42.751C113.376 43.5836 114.27 44.1565 115.277 44.3528C116.284 44.549 117.327 44.3539 118.195 43.8071C119.063 43.2603 119.689 42.4032 119.946 41.4105C120.203 40.4179 120.072 39.3645 119.579 38.4653C119.086 37.5662 118.268 36.889 117.293 36.572Z" fill="#ECEBBE"/>
                <path d="M124.872 46.741C123.211 46.1171 122.347 43.8617 122.952 41.5054C123.21 40.3782 123.816 39.3607 124.685 38.5973C125.036 38.2436 125.472 37.987 125.952 37.8526C126.432 37.7182 126.939 37.7106 127.423 37.8305C127.906 37.9504 128.351 38.1938 128.712 38.5368C129.073 38.8798 129.34 39.3108 129.485 39.7874C129.892 40.8702 129.95 42.0529 129.653 43.1706C129.038 45.6468 127.037 47.2928 125.189 46.8322C125.081 46.8113 124.975 46.7808 124.872 46.741ZM127.469 38.511L127.243 38.4438C126.855 38.3654 126.454 38.3817 126.074 38.4911C125.694 38.6005 125.346 38.7999 125.059 39.0724C124.292 39.7527 123.757 40.6553 123.528 41.6542C122.995 43.8041 123.787 45.858 125.309 46.2323C126.83 46.6066 128.491 45.167 129.024 43.0219C129.291 42.0317 129.241 40.9826 128.88 40.0226C128.764 39.6857 128.578 39.3771 128.335 39.1166C128.092 38.8562 127.797 38.6498 127.469 38.511Z" fill="#ECEBBE"/>
                <path d="M120.432 41.1789L120.135 40.6366C120.936 40.1903 122.684 39.7296 123.562 41.1165L123.034 41.4428C122.252 40.1999 120.504 41.1357 120.432 41.1789Z" fill="#ECEBBE"/>
                <path d="M99.9458 38.3477L99.4658 37.9638C99.6482 37.7383 101.266 35.7563 102.663 35.8043C103.973 35.8523 111.97 38.2037 112.311 38.3237L112.133 38.9188C109.839 38.2373 103.656 36.457 102.643 36.4234C101.712 36.3898 100.383 37.8006 99.9458 38.3477Z" fill="#ECEBBE"/>
                <path d="M106.123 38.4484C106.123 38.4484 104.832 32.2675 102.163 32.5314C99.4941 32.7953 99.0429 40.747 101.179 42.777C101.434 43.056 101.743 43.2806 102.087 43.4372C102.431 43.5937 102.804 43.6791 103.182 43.688C103.56 43.697 103.936 43.6294 104.287 43.4893C104.638 43.3493 104.957 43.1396 105.225 42.8729L106.123 38.4484Z" fill="#ECEBBE"/>
                <path d="M102.091 34.8156C102.053 34.7916 102.019 34.8444 102.048 34.878C103.521 36.3561 103.747 38.4052 103.675 40.3919C103.425 39.5089 102.859 38.8371 101.707 39.0914C101.649 39.0914 101.664 39.1922 101.707 39.2066C102.062 39.2394 102.399 39.3824 102.669 39.6155C102.939 39.8487 103.13 40.1604 103.214 40.5071C103.355 41.0857 103.434 41.6777 103.449 42.273C103.449 42.4698 103.785 42.5082 103.805 42.273C103.802 42.2571 103.802 42.241 103.805 42.2251C104.832 40.008 104.429 36.0825 102.091 34.8156Z" fill="#263238"/>
                <path d="M139.032 81.8301C140.247 78.634 168.403 84.4215 167.28 89.3211C163.157 107.269 151.666 123.307 148.762 126.973C144.951 131.772 137.367 130.706 134.602 127.798C130.594 123.58 137.832 84.9973 139.032 81.8301Z" fill="#ECEBBE"/>
                <path d="M137.05 90.6407C136.424 92.3607 135.928 94.1253 135.567 95.9195C135.159 97.6951 134.804 99.4802 134.516 101.275C133.946 104.906 133.626 108.571 133.556 112.245C133.503 114.299 133.556 116.348 133.556 118.402C133.556 118.45 133.632 118.445 133.632 118.402C133.632 116.583 133.743 114.75 133.892 112.936C134.041 111.122 134.223 109.275 134.458 107.456C134.914 103.881 135.418 100.31 136.066 96.7641C136.245 95.7691 136.433 94.7774 136.628 93.7888C136.834 92.7618 137.069 91.7397 137.204 90.6983C137.204 90.6215 137.084 90.5544 137.05 90.6407Z" fill="#263238"/>
                <path d="M132.24 31.2453C132.055 33.6892 131.689 36.1161 131.145 38.506C131.054 38.9139 130.953 39.3314 130.853 39.7441C130.449 41.3853 129.969 43.0361 129.413 44.6245C129.285 45.0084 129.15 45.3875 129.009 45.7619C127.305 50.364 125.054 54.323 122.52 56.0026C121.876 56.4341 121.193 56.8037 120.48 57.1063C116.847 54.9879 113.813 51.9801 111.663 48.3659C109.513 44.7518 108.319 40.6502 108.192 36.4473L107.424 36.2361C107.544 36.049 107.669 35.857 107.789 35.6555C107.909 35.4539 108.057 35.1996 108.187 34.9597C108.379 28.7813 110.876 22.8981 115.185 18.466C121.219 16.844 128.443 19.1618 131.481 24.455C132.221 25.7651 132.446 28.2317 132.24 31.2453Z" fill="#ECEBBE"/>
                <path d="M119.592 35.9098C119.082 35.9362 118.571 35.9362 118.061 35.9098C117.471 35.9098 117 35.953 116.496 35.6027C116.361 35.4879 116.268 35.3305 116.233 35.1563C116.198 34.982 116.223 34.801 116.304 34.6429C116.674 33.9903 117.394 33.8223 118.104 33.8079C118.817 33.7657 119.52 33.9897 120.077 34.4365C120.201 34.5459 120.289 34.6891 120.332 34.8483C120.375 35.0075 120.371 35.1759 120.319 35.3325C120.268 35.4892 120.171 35.6273 120.042 35.7299C119.913 35.8325 119.757 35.895 119.592 35.9098Z" fill="#263238"/>
                <path d="M119.146 38.3907C119.146 38.3475 119.045 38.4291 119.036 38.4722C118.848 39.6192 118.436 40.9005 117.332 41.0732C117.298 41.0732 117.288 41.1356 117.332 41.1404C118.618 41.1884 119.146 39.4848 119.146 38.3907Z" fill="#263238"/>
                <path d="M118.363 36.9752C116.558 36.4713 115.685 40.1184 117.355 40.5839C119.025 41.0494 119.875 37.3975 118.363 36.9752Z" fill="#263238"/>
                <path d="M125.707 39.773C125.707 39.7346 125.784 39.8497 125.77 39.8977C125.462 41.0159 125.29 42.3499 126.25 42.969C126.278 42.969 126.25 43.0458 126.226 43.0314C125.006 42.5563 125.237 40.7903 125.707 39.773Z" fill="#263238"/>
                <path d="M126.998 38.8372C128.846 39.1347 128.117 42.8107 126.403 42.5371C124.689 42.2636 125.448 38.5877 126.998 38.8372Z" fill="#263238"/>
                <path d="M128.871 37.0279C128.391 36.8215 127.973 36.548 127.512 36.3032C126.998 36.1144 126.559 35.7621 126.264 35.3003C126.201 35.1373 126.194 34.9582 126.243 34.7907C126.293 34.6231 126.396 34.4767 126.538 34.3741C127.167 33.9662 127.887 34.1534 128.525 34.4701C129.172 34.7692 129.685 35.2975 129.965 35.9529C130.019 36.1048 130.029 36.2691 129.993 36.4264C129.957 36.5837 129.877 36.7274 129.762 36.8405C129.647 36.9536 129.502 37.0313 129.344 37.0645C129.186 37.0977 129.022 37.085 128.871 37.0279Z" fill="#263238"/>
                <path d="M115.363 48.1516C115.57 48.4779 115.752 48.905 116.155 49.0394C116.577 49.1329 117.015 49.1329 117.437 49.0394C117.445 49.0391 117.454 49.0413 117.461 49.0457C117.468 49.0501 117.474 49.0566 117.477 49.0643C117.481 49.0719 117.482 49.0805 117.481 49.0888C117.48 49.0972 117.476 49.105 117.47 49.1113C117.256 49.2947 116.996 49.4165 116.718 49.4638C116.44 49.5112 116.154 49.4822 115.891 49.3801C115.676 49.2653 115.499 49.0891 115.384 48.8738C115.268 48.6585 115.219 48.4138 115.243 48.1708C115.238 48.1036 115.33 48.1036 115.363 48.1516Z" fill="#263238"/>
                <path d="M117.36 45.0516C117.54 45.5672 117.823 46.0402 118.194 46.4408C118.565 46.8414 119.015 47.1608 119.516 47.379C119.95 47.5751 120.411 47.7076 120.884 47.7725C120.973 47.7929 121.065 47.8041 121.157 47.8061C121.242 47.8114 121.327 47.8114 121.412 47.8061C121.475 47.8102 121.537 47.793 121.59 47.7573C121.642 47.7217 121.68 47.6696 121.7 47.6094C121.705 47.5711 121.705 47.5324 121.7 47.4942V47.3934C121.93 46.5296 122.18 45.1859 122.18 45.1859C122.458 45.4211 123.903 46.5392 123.984 46.0785C124.661 42.2395 125.424 38.0213 125.28 34.0814C125.28 34.0534 125.269 34.0266 125.25 34.0068C125.23 33.987 125.203 33.9758 125.175 33.9758C125.147 33.9758 125.12 33.987 125.1 34.0068C125.08 34.0266 125.069 34.0534 125.069 34.0814C124.272 37.7189 124.109 41.4764 123.384 45.1188C122.871 44.7215 122.303 44.3985 121.7 44.159C121.57 44.159 121.296 46.76 121.162 47.1391C121.164 47.1518 121.164 47.1648 121.162 47.1775C119.66 47.0143 118.661 46.3137 117.48 44.9892C117.423 44.8932 117.312 44.946 117.36 45.0516Z" fill="#263238"/>
                <path d="M120.48 47.2112C119.853 47.7121 119.108 48.0427 118.315 48.171C117.882 48.2332 117.44 48.1559 117.053 47.9502C116.203 47.4991 116.381 46.6642 116.717 45.9971C116.898 45.6427 117.121 45.3108 117.379 45.0085C118.151 46.0551 119.238 46.8271 120.48 47.2112Z" fill="#263238"/>
                <path d="M118.311 48.1854C117.877 48.2476 117.435 48.1702 117.048 47.9646C116.199 47.5135 116.376 46.6785 116.712 46.0115C117.653 46.3426 118.455 47.2256 118.311 48.1854Z" fill="#FF97B8"/>
                <path d="M132.998 30.9719C132.744 31.0774 132.49 31.1686 132.24 31.2454C125.366 33.3761 118.68 25.7075 118.68 25.7075C117.868 26.9619 116.672 27.9201 115.271 28.4391C113.869 28.958 112.337 29.0099 110.904 28.5868C110.159 30.7827 109.246 32.9181 108.173 34.9741C108.302 30.8935 109.437 26.9078 111.477 23.3709C113.516 19.834 116.398 16.8552 119.866 14.699C122.055 15.4671 124.186 16.3941 126.24 17.4727C127.091 16.2554 128.215 15.2545 129.523 14.5502C129.838 14.3727 130.186 14.261 130.546 14.2218C130.905 14.1826 131.269 14.2168 131.615 14.3222C131.961 14.4276 132.282 14.6021 132.559 14.8352C132.835 15.0682 133.062 15.3549 133.224 15.6779C132.531 16.3512 131.998 17.1711 131.664 18.0774C130.522 21.0622 133.046 21.5853 133.608 18.778C133.856 17.7336 133.72 16.6349 133.224 15.6827C134.851 14.1135 137.011 13.9407 138.024 15.2028C139.915 17.4199 138.926 28.5005 132.998 30.9719Z" fill="#263238"/>
                <path d="M113.065 37.1624L112.877 37.791L112.441 37.6615C111.361 37.3447 109.767 36.8841 108.178 36.4426L107.41 36.2314C107.53 36.0443 107.655 35.8523 107.775 35.6508L108.164 35.7611C110.084 36.289 111.941 36.8313 112.709 37.0568L113.065 37.1624Z" fill="#ECEBBE"/>
                <g opacity="0.3">
                <path opacity="0.3" d="M137.604 57.5635C132.822 58.6166 127.835 58.2281 123.273 56.4473C118.712 54.6665 114.781 51.5734 111.978 47.5591C109.175 43.5448 107.625 38.7896 107.526 33.8949C107.426 29.0002 108.78 24.1858 111.417 20.0606C114.054 15.9354 117.856 12.6847 122.341 10.7196C126.826 8.75443 131.793 8.16312 136.614 9.02042C141.436 9.87773 145.894 12.1451 149.427 15.5359C152.959 18.9267 155.406 23.2885 156.459 28.0698C157.158 31.2441 157.225 34.5251 156.656 37.7254C156.087 40.9256 154.893 43.9825 153.142 46.7213C151.391 49.4601 149.118 51.8273 146.452 53.6877C143.786 55.548 140.779 56.865 137.604 57.5635Z" fill="white"/>
                <path opacity="0.4" d="M110.565 45.2736C113.702 50.9971 118.971 55.2518 125.227 57.1128C131.484 58.9738 138.223 58.2907 143.978 55.212L110.565 45.2736Z" fill="#EBEBEB"/>
                <path opacity="0.4" d="M111.276 20.3053L157.04 33.9196C157.079 31.9538 156.886 29.9902 156.464 28.0698C155.985 25.8668 155.201 23.7412 154.136 21.7545L120.353 11.7009C116.641 13.7458 113.516 16.7083 111.276 20.3053Z" fill="#EBEBEB"/>
                <path opacity="0.4" d="M107.705 36.2806L151.294 49.2375C152.333 47.9905 153.247 46.6453 154.025 45.2209L107.609 31.4098C107.475 33.0324 107.508 34.6645 107.705 36.2806Z" fill="#EBEBEB"/>
                <path opacity="0.4" d="M109.692 23.2997L156.732 37.2979C156.78 37.0004 156.824 36.7028 156.857 36.4053L110.067 22.4886C109.937 22.7526 109.808 23.0213 109.692 23.2997Z" fill="#EBEBEB"/>
                <path opacity="0.3" d="M128.187 8.98468L149.067 15.1944C148.524 14.6905 147.953 14.2346 147.363 13.7547L130.404 8.71594C129.661 8.76744 128.921 8.85714 128.187 8.98468Z" fill="#EBEBEB"/>
                </g>
                <path d="M141.647 90.4833L148.004 89.0837L141.848 61.1379L135.491 62.5374L141.647 90.4833Z" fill="#EBEBEB"/>
                <path d="M159.802 29.7961C158.631 24.4812 155.91 19.6327 151.984 15.8637C148.057 12.0947 143.1 9.57455 137.741 8.62183C132.381 7.66912 126.86 8.32667 121.874 10.5113C116.888 12.696 112.663 16.3096 109.731 20.8953C106.8 25.481 105.294 30.8328 105.405 36.2739C105.516 41.715 107.238 47.001 110.354 51.4635C113.47 55.9261 117.839 59.3647 122.91 61.3446C127.98 63.3245 133.524 63.7567 138.84 62.5866C145.968 61.0152 152.18 56.6784 156.111 50.5296C160.041 44.3808 161.369 36.9232 159.802 29.7961ZM138.24 59.8848C133.458 60.9388 128.471 60.5512 123.909 58.7712C119.347 56.9911 115.415 53.8985 112.612 49.8845C109.808 45.8705 108.258 41.1153 108.157 36.2205C108.057 31.3256 109.41 26.511 112.047 22.3853C114.684 18.2597 118.485 15.0085 122.97 13.0428C127.455 11.0772 132.423 10.4854 137.244 11.3424C142.065 12.1993 146.524 14.4665 150.057 17.8572C153.59 21.2479 156.037 25.6097 157.09 30.3911C157.789 33.5651 157.857 36.8458 157.288 40.0458C156.719 43.2458 155.526 46.3024 153.775 49.0412C152.025 51.78 149.752 54.1472 147.087 56.0078C144.421 57.8684 141.415 59.1858 138.24 59.8848Z" fill="#455A64"/>
                <path d="M144.274 110.148L153.888 108.032L148.436 83.2552L138.816 85.3715L144.274 110.148Z" fill="#263238"/>
                <path d="M165.389 94.9309C165.389 94.9309 172.263 83.6344 163.704 72.8562C160.181 68.4221 151.867 60.4704 135 61.5789C130.997 61.8429 125.482 65.0533 132.173 66.9968C136.925 68.3789 140.357 67.8174 142.978 69.1227C142.978 69.1227 136.445 74.0415 136.867 78.039C137.055 79.8241 137.194 83.7256 138.226 87.4399C138.226 87.4399 139.579 88.3997 140.679 88.2365C140.679 88.2365 143.64 96.7785 154.291 99.4035L165.389 94.9309Z" fill="#ECEBBE"/>
                <path d="M160.089 71.4069C157.334 70.3079 151.45 68.0813 146.002 67.9277C139.949 67.7598 128.241 69.0698 128.169 73.5376C128.112 76.8152 132.624 76.7048 132.624 76.7048C132.624 76.7048 127.901 78.2644 128.501 81.2349C129.043 83.9415 132.528 83.6344 132.528 83.6344C132.528 83.6344 129.825 85.074 130.853 87.579C132.086 90.5927 137.544 87.0991 141.413 89.057C142.305 89.5177 160.089 71.4069 160.089 71.4069Z" fill="#ECEBBE"/>
                <path d="M146.88 74.3486C144.12 73.7439 137.492 73.9743 132.682 76.5992C132.572 76.6568 132.557 76.772 132.682 76.7384C137.303 75.391 142.071 74.6079 146.88 74.4062C146.924 74.4062 146.924 74.3822 146.88 74.3486Z" fill="#263238"/>
                <path d="M146.545 80.4527C144.106 80.1744 137.285 80.7167 132.505 83.5432C132.394 83.6056 132.389 83.7208 132.505 83.6824C137.053 82.0976 141.766 81.0334 146.554 80.5103C146.562 80.509 146.569 80.5048 146.573 80.4985C146.578 80.4922 146.579 80.4844 146.578 80.4767C146.577 80.4691 146.573 80.4623 146.566 80.4578C146.56 80.4533 146.552 80.4515 146.545 80.4527Z" fill="#263238"/>
                <path d="M156.37 100.138C153.926 99.5577 151.568 98.6658 149.352 97.4838C147.121 96.2768 145.147 94.6468 143.539 92.6849C143.059 92.0851 142.579 91.4612 142.162 90.823C141.744 90.1847 141.283 89.4601 140.789 88.8266C140.751 88.7787 140.659 88.8266 140.688 88.8698C141.244 90.2018 141.929 91.4764 142.733 92.6753C143.454 93.7964 144.315 94.8206 145.296 95.7226C147.233 97.4456 149.528 98.7174 152.016 99.4465C153.429 99.8398 154.875 100.102 156.336 100.229C156.408 100.248 156.437 100.152 156.37 100.138Z" fill="#263238"/>
                <path d="M86.304 63.988C73.9392 80.5872 53.304 123.849 50.2704 130.212C48.2112 134.531 70.7856 145.904 71.7408 142.766C72.9696 138.744 93.1536 85.6596 95.9808 66.3058C97.5264 55.6956 90.5808 58.2438 86.304 63.988Z" fill="#ECEBBE"/>
                <path d="M72.3504 140.606C71.8272 144.517 69.168 152.397 67.1904 153.602C65.2128 154.806 54.8208 154.561 49.584 154.561C44.3472 154.561 43.344 153.098 43.4064 151.298C43.4688 149.499 54.48 149.153 54.48 149.153C54.48 149.153 39.8016 153.338 36.1104 154.043C32.4192 154.749 23.9184 156.874 22.6704 153.818C22.1184 152.436 24.4464 151.039 24.4464 151.039C24.4464 151.039 20.88 152.08 20.3088 150.31C19.6656 148.289 22.5024 147.181 22.5024 147.181C22.5024 147.181 20.2704 147.627 20.2176 145.981C20.1216 142.286 34.08 140.482 34.08 140.482C34.08 140.482 23.3952 145.684 23.04 142.679C22.56 138.528 44.4672 133.974 46.7472 132.165C48.4061 130.783 49.9577 129.276 51.3888 127.659L72.3504 140.606Z" fill="#ECEBBE"/>
                <path d="M43.4832 152.191C43.4462 151.936 43.4704 151.676 43.5539 151.432C43.6373 151.189 43.7776 150.968 43.9632 150.79C44.3396 150.445 44.7712 150.166 45.24 149.964C46.2084 149.553 47.2126 149.232 48.24 149.004C50.304 148.481 52.4208 148.112 54.528 147.565C52.3558 147.532 50.1883 147.774 48.0768 148.285C46.9979 148.532 45.959 148.929 44.9904 149.465C44.4889 149.741 44.0494 150.116 43.6992 150.569C43.527 150.798 43.4167 151.068 43.3789 151.352C43.341 151.636 43.377 151.925 43.4832 152.191Z" fill="#263238"/>
                <path d="M24.4805 151.02C25.9925 150.65 27.4805 150.228 28.9637 149.796C30.4469 149.364 31.9253 148.913 33.4037 148.467L37.8245 147.085C39.2981 146.605 40.7717 146.163 42.2597 145.717C40.7149 145.887 39.1834 146.161 37.6757 146.538C36.1589 146.883 34.6709 147.315 33.1877 147.766C31.7045 148.217 30.2357 148.726 28.7861 149.263C27.3365 149.801 25.8725 150.367 24.4805 151.02Z" fill="#263238"/>
                <path d="M38.6974 142.046C37.2736 142.135 35.8584 142.331 34.4638 142.631C33.0643 142.91 31.6814 143.265 30.3214 143.697C28.9581 144.119 27.6199 144.619 26.3134 145.194C25.0022 145.754 23.7333 146.409 22.5166 147.152C23.8654 146.672 25.1806 146.168 26.5198 145.712C27.859 145.256 29.1934 144.805 30.5422 144.393C31.891 143.98 33.2398 143.572 34.5982 143.174C35.9566 142.775 37.3198 142.43 38.6974 142.046Z" fill="#263238"/>
                <path d="M36.5379 138.552C34.2446 138.737 31.9854 139.221 29.8179 139.992C27.6292 140.717 25.5457 141.727 23.6211 142.996C25.7907 142.224 27.9027 141.408 30.0579 140.688C32.2131 139.968 34.3491 139.258 36.5379 138.552Z" fill="#263238"/>
                <path d="M91.5599 92.4402L66.5039 91.7492C66.5039 91.7492 80.1599 70.02 87.4751 62.0491C95.1119 53.7471 99.2687 54.4621 96.6623 69.1706C94.0367 83.9798 91.5599 92.4402 91.5599 92.4402Z" fill="#FA5064"/>
                <path d="M89.9904 88.0829C89.3952 87.9966 88.7856 87.9966 88.1904 87.9678L86.3424 87.867C85.1008 87.8062 83.8544 87.7566 82.6032 87.7182C80.1072 87.6415 77.616 87.5551 75.1248 87.5215C74.4384 87.5215 73.752 87.5215 73.0656 87.5215C72.3792 87.5215 71.6256 87.5935 70.9056 87.5983C70.8624 87.5983 70.8672 87.6654 70.9056 87.6654C71.52 87.7038 72.1344 87.795 72.7536 87.8334C73.3728 87.8718 74.016 87.9102 74.6496 87.9342C75.8976 87.9822 77.1456 88.0254 78.3936 88.0542C80.8464 88.1117 83.304 88.1789 85.7568 88.1837H87.84C88.5552 88.1837 89.28 88.2221 90 88.1597C90.0192 88.1453 90.0288 88.0877 89.9904 88.0829Z" fill="#263238"/>
                <path d="M96.4175 76.1482C96.4175 76.1002 96.3599 76.0714 96.3359 76.1194C95.8711 76.9752 95.4731 77.8656 95.1455 78.7827C95.2319 78.346 95.3327 77.9093 95.4143 77.4678C95.6879 76.057 95.9375 74.6365 96.2159 73.2257C96.2129 73.2172 96.2074 73.2098 96.2001 73.2047C96.1928 73.1995 96.184 73.1967 96.1751 73.1967C96.1661 73.1967 96.1573 73.1995 96.15 73.2047C96.1427 73.2098 96.1372 73.2172 96.1343 73.2257C95.4863 75.6539 94.7327 78.0581 94.1567 80.5055C93.5807 82.9529 92.9999 85.4003 92.4623 87.8573C92.1695 89.2154 91.9055 90.9094 91.6079 92.2627C90.8831 92.3491 89.9519 92.2627 89.1695 92.3635C89.1023 92.3635 89.1215 92.4834 89.1695 92.4834C89.9951 92.5074 90.9791 92.6946 91.8479 92.6418C91.9583 92.6418 94.0607 83.9127 94.9343 79.7329C95.4719 78.5428 95.9711 77.3527 96.4175 76.1482Z" fill="#263238"/>
                <path d="M114.24 62.577C113.89 63.7239 113.491 64.8565 113.035 65.965C112.655 66.9637 112.187 67.9271 111.639 68.8443C110.602 70.4807 109.008 71.834 106.987 71.8292C105.067 71.8292 103.311 70.7159 102.082 69.2954C100.738 67.7454 99.845 65.8018 99.0434 63.9303C98.5634 62.7738 98.0834 61.5932 97.6994 60.3983C97.5026 59.7937 97.2962 59.189 97.1234 58.5748C96.9506 57.9605 96.8018 57.3415 96.5906 56.7416C96.5906 56.6936 96.485 56.6984 96.4994 56.7416C96.8546 58.1237 97.8866 62.5002 98.2994 63.6136C99.0578 65.6531 99.9122 67.7598 101.28 69.4778C102.576 71.0998 104.338 72.3283 106.445 72.5298C108.552 72.7314 110.357 71.6228 111.519 69.9528C112.202 68.9009 112.735 67.759 113.103 66.5601C113.549 65.2452 113.923 63.8967 114.264 62.5482C114.283 62.5674 114.24 62.5626 114.24 62.577Z" fill="#263238"/>
                <path d="M192.893 155.723H158.473L156.937 125.471H192.893V155.723Z" fill="#DBDBDB"/>
                <path d="M192.894 125.471L205.258 109.995L215.098 125.471L213.562 155.723H192.894V125.471Z" fill="#C7C7C7"/>
                <path d="M168.951 105.081H205.695L218.948 126.695H214.681L205.695 111.732L195.332 128.48H153.903L168.951 105.081Z" fill="#A6A6A6"/>
                <path d="M173.209 111.919H165.308L161.094 116.967H170.214L173.209 111.919Z" fill="#C7C7C7"/>
                <path d="M167.223 116.104V122.251H162.826V116.104L165.025 113.354L167.223 116.104Z" fill="#DBDBDB"/>
                <path d="M167.223 116.099H170.679L167.223 122.251V116.099Z" fill="#C7C7C7"/>
                <path d="M166.162 116.2H163.661V120.418H166.162V116.2Z" fill="#FBFAFF"/>
                <path d="M164.775 116.492H163.969V117.85H164.775V116.492Z" fill="#EBEBEB"/>
                <path d="M165.85 116.492H165.044V117.85H165.85V116.492Z" fill="#EBEBEB"/>
                <path d="M164.775 118.095H163.969V120.106H164.775V118.095Z" fill="#EBEBEB"/>
                <path d="M165.85 118.095H165.044V120.106H165.85V118.095Z" fill="#EBEBEB"/>
                <path d="M192.552 111.919H184.651L180.696 116.967H189.538L192.552 111.919Z" fill="#C7C7C7"/>
                <path d="M186.567 116.104V122.251H182.17V116.104L184.368 113.354L186.567 116.104Z" fill="#DBDBDB"/>
                <path d="M186.567 116.099H190.028L186.567 122.251V116.099Z" fill="#C7C7C7"/>
                <path d="M185.506 116.2H183.005V120.418H185.506V116.2Z" fill="#FBFAFF"/>
                <path d="M184.119 116.492H183.312V117.85H184.119V116.492Z" fill="#EBEBEB"/>
                <path d="M185.199 116.492H184.393V117.85H185.199V116.492Z" fill="#EBEBEB"/>
                <path d="M184.119 118.095H183.312V120.106H184.119V118.095Z" fill="#EBEBEB"/>
                <path d="M185.199 118.095H184.393V120.106H185.199V118.095Z" fill="#EBEBEB"/>
                <path d="M199.105 104.207H194.708V109.995H199.105V104.207Z" fill="#DBDBDB"/>
                <path d="M199.104 104.207H202.56V105.138L199.104 109.995V104.207Z" fill="#C7C7C7"/>
                <path d="M199.104 102.24H194.213V104.207H199.104V102.24Z" fill="#C7C7C7"/>
                <path d="M202.94 102.24H199.104V104.207H202.94V102.24Z" fill="#A6A6A6"/>
                <path d="M167.555 130.956H162.111V140.146H167.555V130.956Z" fill="#FBFAFF"/>
                <path d="M164.535 131.594H162.783V134.555H164.535V131.594Z" fill="#EBEBEB"/>
                <path d="M166.882 131.594H165.13V134.555H166.882V131.594Z" fill="#EBEBEB"/>
                <path d="M164.535 135.088H162.783V139.474H164.535V135.088Z" fill="#EBEBEB"/>
                <path d="M166.882 135.088H165.13V139.474H166.882V135.088Z" fill="#EBEBEB"/>
                <path d="M187.771 130.956H182.328V140.146H187.771V130.956Z" fill="#FBFAFF"/>
                <path d="M184.753 131.594H183.001V134.555H184.753V131.594Z" fill="#EBEBEB"/>
                <path d="M187.1 131.594H185.348V134.555H187.1V131.594Z" fill="#EBEBEB"/>
                <path d="M184.753 135.088H183.001V139.474H184.753V135.088Z" fill="#EBEBEB"/>
                <path d="M187.1 135.088H185.348V139.474H187.1V135.088Z" fill="#EBEBEB"/>
                <path d="M167.555 143.726H162.111V152.915H167.555V143.726Z" fill="#FBFAFF"/>
                <path d="M164.535 144.364H162.783V147.325H164.535V144.364Z" fill="#EBEBEB"/>
                <path d="M166.882 144.364H165.13V147.325H166.882V144.364Z" fill="#EBEBEB"/>
                <path d="M164.535 147.858H162.783V152.244H164.535V147.858Z" fill="#EBEBEB"/>
                <path d="M166.882 147.858H165.13V152.244H166.882V147.858Z" fill="#EBEBEB"/>
                <path d="M187.771 143.726H182.328V152.915H187.771V143.726Z" fill="#FBFAFF"/>
                <path d="M184.753 144.364H183.001V147.325H184.753V144.364Z" fill="#EBEBEB"/>
                <path d="M187.1 144.364H185.348V147.325H187.1V144.364Z" fill="#EBEBEB"/>
                <path d="M184.753 147.858H183.001V152.244H184.753V147.858Z" fill="#EBEBEB"/>
                <path d="M187.1 147.858H185.348V152.244H187.1V147.858Z" fill="#EBEBEB"/>
                <path d="M177.635 130.956H172.191V140.146H177.635V130.956Z" fill="#FBFAFF"/>
                <path d="M174.615 131.594H172.863V134.555H174.615V131.594Z" fill="#EBEBEB"/>
                <path d="M176.967 131.594H175.215V134.555H176.967V131.594Z" fill="#EBEBEB"/>
                <path d="M174.615 135.088H172.863V139.474H174.615V135.088Z" fill="#EBEBEB"/>
                <path d="M176.967 135.088H175.215V139.474H176.967V135.088Z" fill="#EBEBEB"/>
                <path d="M177.635 143.788H172.191V152.978H177.635V143.788Z" fill="#FBFAFF"/>
                <path d="M174.615 144.422H172.863V147.382H174.615V144.422Z" fill="#EBEBEB"/>
                <path d="M176.967 144.422H175.215V147.382H176.967V144.422Z" fill="#EBEBEB"/>
                <path d="M174.615 147.92H172.863V152.306H174.615V147.92Z" fill="#EBEBEB"/>
                <path d="M176.967 147.92H175.215V152.306H176.967V147.92Z" fill="#EBEBEB"/>
                <path d="M202.676 140.146H208.119V130.956H202.676V140.146Z" fill="#FBFAFF"/>
                <path d="M207.447 131.594H205.695V134.555H207.447V131.594Z" fill="#EBEBEB"/>
                <path d="M205.095 131.594H203.343V134.555H205.095V131.594Z" fill="#EBEBEB"/>
                <path d="M207.447 135.088H205.695V139.474H207.447V135.088Z" fill="#EBEBEB"/>
                <path d="M205.095 135.088H203.343V139.474H205.095V135.088Z" fill="#EBEBEB"/>
                <path d="M202.676 125.941H208.119V118.162H202.676V125.941Z" fill="#FBFAFF"/>
                <path d="M207.447 118.796H205.695V121.757H207.447V118.796Z" fill="#EBEBEB"/>
                <path d="M205.095 118.796H203.343V121.757H205.095V118.796Z" fill="#EBEBEB"/>
                <path d="M207.447 122.323H205.695V125.284H207.447V122.323Z" fill="#EBEBEB"/>
                <path d="M205.095 122.323H203.343V125.284H205.095V122.323Z" fill="#EBEBEB"/>
                <path d="M201.901 155.779L208.501 155.81L208.557 143.828L201.958 143.796L201.901 155.779Z" fill="#FBFAFF"/>
                <path d="M204.538 144.626L204.529 146.421L205.95 146.428L205.959 144.633L204.538 144.626Z" fill="#EBEBEB"/>
                <path d="M202.681 144.616L202.673 146.411L204.094 146.418L204.102 144.623L202.681 144.616Z" fill="#EBEBEB"/>
                <path d="M203.714 152.937L206.714 152.951L206.716 152.52L203.716 152.505L203.714 152.937Z" fill="#EBEBEB"/>
                <path d="M205.426 143.711V145.506H206.847V143.711H205.426Z" fill="#EBEBEB"/>
                <path d="M204.533 146.892L204.524 148.687L205.945 148.694L205.954 146.899L204.533 146.892Z" fill="#EBEBEB"/>
                <path d="M202.662 146.878L202.653 148.673L204.074 148.679L204.083 146.885L202.662 146.878Z" fill="#EBEBEB"/>
                <path d="M206.394 146.902L206.386 148.697L207.807 148.703L207.815 146.909L206.394 146.902Z" fill="#EBEBEB"/>
                <path d="M204.523 149.163L204.515 150.958L205.935 150.965L205.944 149.17L204.523 149.163Z" fill="#EBEBEB"/>
                <path d="M202.657 149.154L202.648 150.948L204.069 150.955L204.078 149.16L202.657 149.154Z" fill="#EBEBEB"/>
                <path d="M206.384 149.173L206.376 150.968L207.797 150.974L207.805 149.18L206.384 149.173Z" fill="#EBEBEB"/>
                <path d="M98.1608 155.804H67.5848L66.2168 140.99H98.1608V155.804Z" fill="#DBDBDB"/>
                <path d="M98.1504 140.971L109.133 127.227L117.878 140.971L116.51 155.785H98.1504V140.971Z" fill="#C7C7C7"/>
                <path d="M76.7092 122.88H109.92L122.223 143.663H118.868L109.469 128.019L100.44 143.663H63.6436L76.7092 122.88Z" fill="#A6A6A6"/>
                <path d="M75.7251 146.49H70.8867V153.4H75.7251V146.49Z" fill="#FBFAFF"/>
                <path d="M73.0376 147.056H71.4824V149.686H73.0376V147.056Z" fill="#DBDBDB"/>
                <path d="M75.1255 147.056H73.5703V149.686H75.1255V147.056Z" fill="#DBDBDB"/>
                <path d="M73.0376 150.185H71.4824V152.815H73.0376V150.185Z" fill="#DBDBDB"/>
                <path d="M75.1255 150.185H73.5703V152.815H75.1255V150.185Z" fill="#DBDBDB"/>
                <path d="M93.4946 146.49H88.6562V153.4H93.4946V146.49Z" fill="#FBFAFF"/>
                <path d="M90.8072 147.056H89.252V149.686H90.8072V147.056Z" fill="#DBDBDB"/>
                <path d="M92.8951 147.056H91.3398V149.686H92.8951V147.056Z" fill="#DBDBDB"/>
                <path d="M90.8072 150.185H89.252V152.815H90.8072V150.185Z" fill="#DBDBDB"/>
                <path d="M92.8951 150.185H91.3398V152.815H92.8951V150.185Z" fill="#DBDBDB"/>
                <path d="M84.6385 146.12H79.3057V155.804H84.6385V146.12Z" fill="#FBFAFF"/>
                <path d="M82.5456 146.787H81.3984V148.237H82.5456V146.787Z" fill="#DBDBDB"/>
                <path d="M84.0535 146.787H82.9062V148.237H84.0535V146.787Z" fill="#DBDBDB"/>
                <path d="M83.1847 153.146H80.7607V153.496H83.1847V153.146Z" fill="#DBDBDB"/>
                <path d="M81.0388 146.787H79.8916V148.237H81.0388V146.787Z" fill="#DBDBDB"/>
                <path d="M82.5456 148.62H81.3984V150.07H82.5456V148.62Z" fill="#DBDBDB"/>
                <path d="M84.0535 148.62H82.9062V150.07H84.0535V148.62Z" fill="#DBDBDB"/>
                <path d="M81.0388 148.62H79.8916V150.07H81.0388V148.62Z" fill="#DBDBDB"/>
                <path d="M82.5456 150.454H81.3984V151.903H82.5456V150.454Z" fill="#DBDBDB"/>
                <path d="M84.0535 150.454H82.9062V151.903H84.0535V150.454Z" fill="#DBDBDB"/>
                <path d="M81.0388 150.454H79.8916V151.903H81.0388V150.454Z" fill="#DBDBDB"/>
                <path d="M83.7363 122.049H79.8291V127.189H83.7363V122.049Z" fill="#DBDBDB"/>
                <path d="M83.7363 122.049H86.8083V122.875L83.7363 127.189V122.049Z" fill="#C7C7C7"/>
                <path d="M83.7366 120.298H79.3926V122.045H83.7366V120.298Z" fill="#C7C7C7"/>
                <path d="M87.1443 120.298H83.7363V122.045H87.1443V120.298Z" fill="#A6A6A6"/>
                <path d="M110.247 155.809H120.164L121.109 145.593H110.247V155.809Z" fill="#C7C7C7"/>
                <path d="M110.246 145.593L105.346 143.38L105.456 155.809H110.246V145.593Z" fill="#DBDBDB"/>
                <path d="M117.84 148.237H113.669V155.805H117.84V148.237Z" fill="#FBFAFF"/>
                <path d="M116.203 148.755H115.306V149.887H116.203V148.755Z" fill="#DBDBDB"/>
                <path d="M117.38 148.755H116.482V149.887H117.38V148.755Z" fill="#DBDBDB"/>
                <path d="M116.703 153.218H114.807V153.491H116.703V153.218Z" fill="#DBDBDB"/>
                <path d="M115.027 148.755H114.13V149.887H115.027V148.755Z" fill="#DBDBDB"/>
                <path d="M104.598 142.872H119.007L123.663 146.413H109.83L104.598 142.872Z" fill="#A6A6A6"/>
                <path d="M109.83 146.413H104.598V142.872L109.83 146.413Z" fill="#C7C7C7"/>
                <path d="M106.733 137.694C106.733 138.183 106.879 138.661 107.15 139.067C107.422 139.474 107.809 139.79 108.261 139.977C108.713 140.164 109.21 140.213 109.689 140.117C110.169 140.021 110.61 139.786 110.955 139.439C111.301 139.093 111.536 138.652 111.63 138.173C111.725 137.693 111.676 137.196 111.488 136.744C111.3 136.293 110.982 135.907 110.575 135.636C110.168 135.365 109.69 135.221 109.201 135.222C108.546 135.223 107.918 135.484 107.456 135.948C106.993 136.411 106.733 137.039 106.733 137.694Z" fill="#FBFAFF"/>
                <path d="M107.285 137.694C107.285 138.203 107.487 138.691 107.848 139.051C108.208 139.411 108.696 139.613 109.205 139.613C109.367 139.614 109.529 139.593 109.685 139.551C110.132 139.429 110.52 139.151 110.778 138.766C111.036 138.381 111.145 137.915 111.087 137.456C111.028 136.996 110.806 136.574 110.46 136.265C110.114 135.957 109.668 135.784 109.205 135.779C109.043 135.778 108.882 135.799 108.725 135.841C108.502 135.895 108.291 135.993 108.106 136.129C107.854 136.305 107.648 136.539 107.505 136.811C107.362 137.084 107.287 137.386 107.285 137.694Z" fill="#C7C7C7"/>
                <path d="M108.937 139.949H109.47V135.4H108.937V139.949Z" fill="#FBFAFF"/>
                <path d="M111.48 137.938V137.406H106.93V137.938H111.48Z" fill="#FBFAFF"/>
                <path d="M124.378 155.809H108.927L107.871 144.383H124.378V155.809Z" fill="#AAC8BE"/>
                <path opacity="0.2" d="M124.378 155.809H108.927L107.871 144.383H124.378V155.809Z" fill="black"/>
                <path d="M124.378 144.383L132.85 133.778L139.594 144.383L138.543 155.809H124.378V144.383Z" fill="#AAC8BE"/>
                <path d="M125.4 145.78H104.434L117.187 129.747H132.936L142.402 145.78H139.997L132.936 134.795L125.4 145.78Z" fill="#AAC8BE"/>
                <path opacity="0.4" d="M125.4 145.78H104.434L117.187 129.747H132.936L142.402 145.78H139.997L132.936 134.795L125.4 145.78Z" fill="black"/>
                <path d="M146.559 115.566H142.191V121.315H146.559V115.566Z" fill="#AAC8BE"/>
                <path opacity="0.2" d="M146.559 115.566H142.191V121.315H146.559V115.566Z" fill="black"/>
                <path d="M149.995 115.566H146.559V121.315H149.995V115.566Z" fill="#AAC8BE"/>
                <path d="M146.559 113.608H141.701V115.561H146.559V113.608Z" fill="#AAC8BE"/>
                <path opacity="0.5" d="M146.559 113.608H141.701V115.561H146.559V113.608Z" fill="black"/>
                <path d="M150.37 113.608H146.559V115.561H150.37V113.608Z" fill="#AAC8BE"/>
                <path opacity="0.4" d="M150.37 113.608H146.559V115.561H150.37V113.608Z" fill="black"/>
                <path d="M161.909 155.795H127.709L126.178 139.224H161.909V155.795Z" fill="#AAC8BE"/>
                <path opacity="0.2" d="M161.909 155.795H127.709L126.178 139.224H161.909V155.795Z" fill="black"/>
                <path d="M161.909 139.224L174.192 123.844L183.975 139.224L182.444 155.795H161.909V139.224Z" fill="#AAC8BE"/>
                <path d="M174.044 124.818L184.143 140.434H187.671L174.346 118.964H137.967L119.535 142.214H163.969L174.044 124.818Z" fill="#AAC8BE"/>
                <path opacity="0.5" d="M174.044 124.818L184.143 140.434H187.671L174.346 118.964H137.967L119.535 142.214H163.969L174.044 124.818Z" fill="black"/>
                <path d="M171.001 141.552H176.41V133.821H171.001V141.552Z" fill="#FBFAFF"/>
                <path d="M175.742 134.45H174V137.391H175.742V134.45Z" fill="#AAC8BE"/>
                <path d="M173.41 134.45H171.668V137.391H173.41V134.45Z" fill="#AAC8BE"/>
                <path d="M175.742 137.953H174V140.894H175.742V137.953Z" fill="#AAC8BE"/>
                <path d="M173.41 137.953H171.668V140.894H173.41V137.953Z" fill="#AAC8BE"/>
                <path d="M136.81 145.372H131.4V153.103H136.81V145.372Z" fill="#FBFAFF"/>
                <path d="M133.81 146.005H132.067V148.947H133.81V146.005Z" fill="#AAC8BE"/>
                <path d="M136.148 146.005H134.405V148.947H136.148V146.005Z" fill="#AAC8BE"/>
                <path d="M133.81 149.508H132.067V152.45H133.81V149.508Z" fill="#AAC8BE"/>
                <path d="M136.148 149.508H134.405V152.45H136.148V149.508Z" fill="#AAC8BE"/>
                <path d="M146.751 145.372H141.342V153.103H146.751V145.372Z" fill="#FBFAFF"/>
                <path d="M143.746 146.005H142.004V148.947H143.746V146.005Z" fill="#AAC8BE"/>
                <path d="M146.083 146.005H144.341V148.947H146.083V146.005Z" fill="#AAC8BE"/>
                <path d="M143.746 149.508H142.004V152.45H143.746V149.508Z" fill="#AAC8BE"/>
                <path d="M146.083 149.508H144.341V152.45H146.083V149.508Z" fill="#AAC8BE"/>
                <path d="M156.687 145.372H151.277V153.103H156.687V145.372Z" fill="#FBFAFF"/>
                <path d="M153.687 146.005H151.944V148.947H153.687V146.005Z" fill="#AAC8BE"/>
                <path d="M156.02 146.005H154.277V148.947H156.02V146.005Z" fill="#AAC8BE"/>
                <path d="M153.687 149.508H151.944V152.45H153.687V149.508Z" fill="#AAC8BE"/>
                <path d="M156.02 149.508H154.277V152.45H156.02V149.508Z" fill="#AAC8BE"/>
                <path d="M170.722 155.79H176.688V144.959H170.722V155.79Z" fill="#FBFAFF"/>
                <path d="M174.351 145.708H173.064V147.33H174.351V145.708Z" fill="#AAC8BE"/>
                <path d="M172.666 145.708H171.38V147.33H172.666V145.708Z" fill="#AAC8BE"/>
                <path d="M172.35 153.213H175.062V152.82H172.35V153.213Z" fill="#AAC8BE"/>
                <path d="M176.031 145.708H174.744V147.33H176.031V145.708Z" fill="#AAC8BE"/>
                <path d="M174.351 147.757H173.064V149.379H174.351V147.757Z" fill="#AAC8BE"/>
                <path d="M172.666 147.757H171.38V149.379H172.666V147.757Z" fill="#AAC8BE"/>
                <path d="M176.031 147.757H174.744V149.379H176.031V147.757Z" fill="#AAC8BE"/>
                <path d="M174.351 149.806H173.064V151.428H174.351V149.806Z" fill="#AAC8BE"/>
                <path d="M172.666 149.806H171.38V151.428H172.666V149.806Z" fill="#AAC8BE"/>
                <path d="M176.031 149.806H174.744V151.428H176.031V149.806Z" fill="#AAC8BE"/>
                <path d="M152.05 124.53H144.197L140.16 129.569H149.05L152.05 124.53Z" fill="#AAC8BE"/>
                <path opacity="0.4" d="M152.05 124.53H144.197L140.16 129.569H149.05L152.05 124.53Z" fill="black"/>
                <path d="M146.103 128.686V134.795H141.73V128.686L143.919 125.956L146.103 128.686Z" fill="#AAC8BE"/>
                <path opacity="0.2" d="M146.103 128.686V134.795H141.73V128.686L143.919 125.956L146.103 128.686Z" fill="black"/>
                <path d="M146.103 128.686H149.539L146.103 134.795V128.686Z" fill="#AAC8BE"/>
                <path d="M145.042 128.782H142.561V132.971H145.042V128.782Z" fill="#FBFAFF"/>
                <path d="M143.67 129.07H142.868V130.418H143.67V129.07Z" fill="#AAC8BE"/>
                <path d="M144.74 129.07H143.938V130.418H144.74V129.07Z" fill="#AAC8BE"/>
                <path d="M143.67 130.668H142.868V132.669H143.67V130.668Z" fill="#AAC8BE"/>
                <path d="M144.74 130.668H143.938V132.669H144.74V130.668Z" fill="#AAC8BE"/>
              </svg>
            </div>
            <div className='infos'>
              <p>Com base nas características analisadas, <br/>
              estimamos que seu imóvel está avaliado em:</p>
              <h1 className='total'>R$ {util.formatToBRL(result?.price)}</h1>
              <a href= {environment.appUrl+'/register'}>Quero {goal} meu imóvel</a>
              <a href="/">Nova Avaliação</a>
              
            </div>
          </div>
        </div>
      </div>
      <AlternativeFooter/>
    </>
  )
}