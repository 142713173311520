import { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useForm } from '../../hooks/useForms';
import { GlobalContext } from '../../providers/provides';
import { ToastContainer,toast } from 'react-toastify';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Truliv from '../../assets/truliv-logo.jpeg';
import './ImmobilePage.scss';
import 'react-toastify/dist/ReactToastify.css';

import immobileLow1 from '../../assets/padrao/baixo/1.png'; 
import immobileLow2 from '../../assets/padrao/baixo/2.png'; 
import immobileLow3 from '../../assets/padrao/baixo/3.png'; 
import immobileLow4 from '../../assets/padrao/baixo/4.png'; 
import immobileLow5 from '../../assets/padrao/baixo/5.png'; 
import immobileLow6 from '../../assets/padrao/baixo/6.png'; 
import immobileLow7 from '../../assets/padrao/baixo/7.png'; 

import immobileMiddle1 from '../../assets/padrao/medio/1.png';
import immobileMiddle2 from '../../assets/padrao/medio/2.png';
import immobileMiddle3 from '../../assets/padrao/medio/3.png';
import immobileMiddle4 from '../../assets/padrao/medio/4.png';
import immobileMiddle5 from '../../assets/padrao/medio/5.png';

import immobileHigh1 from '../../assets/padrao/alto/1.png'; 
import immobileHigh2 from '../../assets/padrao/alto/2.png'; 
import immobileHigh3 from '../../assets/padrao/alto/3.png'; 
import immobileHigh4 from '../../assets/padrao/alto/4.png'; 
import immobileHigh5 from '../../assets/padrao/alto/5.png'; 
import immobileHigh6 from '../../assets/padrao/alto/6.png'; 
import immobileHigh7 from '../../assets/padrao/alto/7.png'; 
 
export default function ImmobilePage(props: any){

  let history = useHistory();
  
  const [selectedOptionConservation, setSelectedOptionConservation] = useState<Number>(0)
  const [selectedOptionCondo, setSelectedOptionCondo] = useState<Number>(0)
  const {setDataEtapa,valuationState,setValuationState} = useContext(GlobalContext);

  const { onSubmit } = useForm(conservationCallback);

  async function conservationCallback() {}

  const notify = () => toast.warn('Selecione uma opção!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const getValues = () =>{
    if(selectedOptionConservation === 0 || selectedOptionCondo === 0) return notify();
    setValuationState((prevState: any) => ({
      ...prevState,
      propertyPattern: selectedOptionConservation,
      condoPattern: selectedOptionCondo
    }));

    setDataEtapa((prevState: any) => ({
      ...prevState,
      stepThu: 'yes'
    }));
    history.push("/2");
  }

  // SABER QUEM FOI SELECIONADO
  useEffect(()=>{
    console.log('valuationState')
    console.log(valuationState)
    if(valuationState.propertyPattern) setSelectedOptionConservation(valuationState.propertyPattern)
    if(valuationState.condoPattern) setSelectedOptionCondo(valuationState.condoPattern)
  },[valuationState])

  return(
      <>
      <div className='container-flex'>
        <div className='contents'>
        
          <div className='container-progress'>
            <div className="progress">
                <ProgressBar now={66}/>
            </div>
            <span>2/3</span>
          </div>
          <ToastContainer />
          <form onSubmit={onSubmit}>
            <span className='sub-title-avaliacao'>Estado de conservação do imóvel</span>
            <div className="container-cards">
              <div className="card-layouts" >
                <input type="radio" name="optradio" defaultChecked={valuationState.propertyPattern === 1} onClick={()=> setSelectedOptionConservation(1)}/>
                <div className='image-project '>
                  {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                  {/* <img className="img-fluid" src={immobileLow1} alt="Card image cap" /> */}
                  <Slider {...settings} className="container-photos">
                    <img src={immobileLow1} alt="" />
                    <img src={immobileLow2} alt="" />
                    <img src={immobileLow3} alt="" />
                    <img src={immobileLow4} alt="" />
                    <img src={immobileLow5} alt="" />
                    <img src={immobileLow6} alt="" />
                    <img src={immobileLow7} alt="" />
                  </Slider> 
                </div>
                <div className="card-layouts-infos">
                  <span className='title-card-immobile'>Padrão Baixo</span>
                  <span className="">Imóvel antigo, bastante desgastado ou com design completamente ultrapassado. Instalações elétrica e hidráulica podem precisar de reparos.</span>
                </div>
              </div>
          
              <div className="card-layouts" >
                <input type="radio" name="optradio" defaultChecked={valuationState.propertyPattern === 2} onClick={()=> setSelectedOptionConservation(2)}/>
                <div className='image-project '>
                  {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                  {/* <img className="img-fluid" src={ Truliv } alt="Card image cap" /> */}
                  <Slider {...settings} className="container-photos">
                    <img src={immobileMiddle1} alt="" />
                    <img src={immobileMiddle2} alt="" />
                    <img src={immobileMiddle3} alt="" />
                    <img src={immobileMiddle4} alt="" />
                    <img src={immobileMiddle5} alt="" />
                  </Slider>
                </div>
                <div className="card-layouts-infos">
                  <span className='title-card-immobile'>Padrão Médio</span>
                  <span className="">Imóvel antigo, bastante desgastado ou com design completamente ultrapassado. Instalações elétrica e hidráulica podem precisar de reparos.</span>
                </div>
              </div>

              <div className="card-layouts" >
                <input type="radio" name="optradio" defaultChecked={valuationState.propertyPattern === 3} onClick={()=> setSelectedOptionConservation(3)}/>
                <div className='image-project'>
                  {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                  {/* <img className="img-fluid" src={ Truliv } alt="Card image cap" /> */}
                  <Slider {...settings} className="container-photos">
                    <img src={immobileHigh1} alt="" />
                    <img src={immobileHigh2} alt="" />
                    <img src={immobileHigh3} alt="" />
                    <img src={immobileHigh4} alt="" />
                    <img src={immobileHigh5} alt="" />
                    <img src={immobileHigh6} alt="" />
                    <img src={immobileHigh7} alt="" />
                  </Slider>
                </div>
                <div className="card-layouts-infos">
                  <span className='title-card-immobile'>Padrão Alto</span>
                  <span className="">Imóvel novo ou reformado recentemente com um projeto arquitetônico moderno incluindo itens de acabamento de alta qualidade, tecnológicos e com design diferenciado. Instalações elétricas e hidráulicas novas ou em perfeito estado de conservação.</span>
                </div>
              </div>    
            </div>
          </form>
        </div>  

        <div className='contents'>
          <form onSubmit={onSubmit}>          
            <span className='sub-title-avaliacao'>Estado de Conservação do Condomínio</span>
            <div className="container-cards">
          
              <div className="card-layouts" >
                <input type="radio" name="optradio" defaultChecked={valuationState.condoPattern === 1} onClick={()=>setSelectedOptionCondo(1)}/>
                <div className='image-project'>
                  {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                  {/* <img className="img-fluid" src={ Truliv } alt="Card image cap" /> */}
                  <Slider {...settings} className="container-photos">
                    <img src={Truliv} alt="" />
                  </Slider>
                </div>
                <div className="card-layouts-infos">
                  <span className='title-card-immobile'>Padrão Baixo</span>
                  <span className="">Imóvel antigo, bastante desgastado ou com design completamente ultrapassado. Instalações elétrica e hidráulica podem precisar de reparos significativos ou até mesmo ser completamente substituídas.</span>
                </div>
              </div>
        
              <div className="card-layouts" >
                <input type="radio" name="optradio" defaultChecked={valuationState.condoPattern === 2} onClick={()=>setSelectedOptionCondo(2)}/>
                <div className='image-project'>
                  {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                  {/* <img className="img-fluid" src={ Truliv } alt="Card image cap" /> */}
                  <Slider {...settings} className="container-photos">
                    <img src={Truliv} alt="" />
                  </Slider>
                </div>
                <div className="card-layouts-infos">
                  <span className='title-card-immobile'>Padrão Médio</span>
                  <span className="">Imóveis novos com acabamento básico ou imóveis antigos reformados há cerca de 10-20 anos, em bom estado de conservação, mas com um design que não representa mais as últimas tendências da arquitetura moderna. Pequenos reparos elétricos e hidráulicos podem ser necessários. </span>
                </div>
              </div>
            
        
              <div className="card-layouts" >
                <input type="radio" name="optradio" defaultChecked={valuationState.condoPattern === 3} onClick={()=>setSelectedOptionCondo(3)}/>
                <div className='image-project '>
                  {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                  {/* <img className="img-fluid" src={ Truliv } alt="Card image cap" /> */}
                  <Slider {...settings} className="container-photos">
                    <img src={Truliv} alt="" />
                  </Slider>
                </div>
                <div className="card-layouts-infos">
                  <span className='title-card-immobile'>Padrão Alto</span>
                  <span className="">Imóvel novo ou reformado recentemente com um projeto arquitetônico moderno incluindo itens de acabamento de alta qualidade, tecnológicos e com design diferenciado. Instalações elétricas e hidráulicas novas ou em perfeito estado de conservação.</span>
                </div>
              </div>
            </div>
            
            <div className='footer-button'>
              <div className='container-footer-action'>
                <Link to="/"><button className='button-back'>Voltar</button></Link>
                <button onClick={()=>getValues()}>Próximo</button>
                {/* <Link to={{ pathname: '/2', state: { fromNotifications: true } }}><button onClick={()=>getValues()}>Próximo</button></Link> */}
                
              </div>
            </div>
          </form>
        </div>
      </div >
      </>
  )
}