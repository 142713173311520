import React from 'react';
import './AlternativeFooter.scss';
import environment from '../../environment';

const AlternativeFooter = () => {
    return (
      <footer className="center-footer">
        <div className="container-footer">
          <div className="logo">
            <svg width="94" height="25" viewBox="0 0 94 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_4_519)">
              <path d="M33.5095 17.7198C33.3033 17.5163 33.1432 17.2703 33.0404 16.9986C32.9377 16.7269 32.8945 16.436 32.9141 16.1459V10.2573H36.4335V6.78297H32.7918V3.18018H29.1926V5.3215C29.1926 5.51317 29.155 5.70296 29.082 5.87997C29.009 6.05698 28.902 6.21774 28.7671 6.35302C28.6323 6.48831 28.4722 6.59545 28.2962 6.66831C28.1201 6.74117 27.9315 6.77831 27.7412 6.77761H26.1462V10.2466H29.0756V16.1138C29.0756 17.8768 29.586 19.233 30.6067 20.1823C31.7066 21.1671 33.1451 21.68 34.6153 21.6117H36.811V18.2551H35.3118C34.6676 18.3073 34.0271 18.1165 33.5148 17.7198" fill="white"/>
              <path d="M44.6261 7.10416C44.0193 7.37546 43.5065 7.82277 43.1534 8.38896V6.78296H39.3149V21.5795H43.1534V13.3461C43.1274 12.9522 43.1926 12.5576 43.3439 12.1935C43.4952 11.8293 43.7285 11.5055 44.0253 11.2476C44.367 10.9091 44.7768 10.6484 45.2272 10.4831C45.6775 10.3178 46.1578 10.2517 46.6357 10.2894H48.2306V6.67053H46.8856C46.117 6.64889 45.3531 6.79691 44.6473 7.10416" fill="white"/>
              <path d="M59.9162 14.0473C59.9194 14.7049 59.7985 15.357 59.56 15.9692C59.3344 16.5598 58.9541 17.0778 58.4595 17.4681C57.9652 17.8764 57.3384 18.0862 56.6998 18.057C56.3325 18.0836 55.9641 18.024 55.6237 17.8828C55.2832 17.7416 54.98 17.5226 54.738 17.2432C54.2548 16.6732 54.0007 15.9419 54.0256 15.1929V6.78821H50.1871V16.2422C50.1086 17.7442 50.6161 19.2176 51.6013 20.3482C52.5406 21.3689 53.8767 21.8792 55.6099 21.8792C56.5266 21.9296 57.4411 21.7454 58.2681 21.3439C58.9858 20.934 59.5892 20.3485 60.0225 19.6415V21.6062H63.7759V6.78821H59.9375L59.9162 14.0473Z" fill="white"/>
              <path d="M70.6502 1.17236H66.8118V21.5846H70.6502V1.17236Z" fill="white"/>
              <path d="M77.4818 6.78271H73.6433V21.5846H77.4818V6.78271Z" fill="white"/>
              <path d="M89.6351 6.82031L87.1895 18.4156H86.2432L83.7923 6.82031H79.4329L83.8668 21.5848H84.9726H86.5197H86.9078H88.3113H89.566L93.9999 6.82031H89.6351Z" fill="white"/>
              <path d="M75.5892 0.000180388C75.2684 -0.0124351 74.9484 0.0398175 74.6479 0.15383C74.3475 0.267843 74.0728 0.441315 73.8401 0.664003C73.6148 0.888344 73.4385 1.15749 73.3222 1.45427C73.206 1.75106 73.1524 2.06897 73.1649 2.38776C73.1505 2.71171 73.2034 3.03514 73.3202 3.33731C73.4371 3.63949 73.6153 3.91375 73.8435 4.14255C74.0718 4.37134 74.3449 4.54963 74.6455 4.66597C74.9461 4.78231 75.2676 4.83415 75.5892 4.81817C75.9058 4.83079 76.2215 4.77685 76.5163 4.6598C76.811 4.54274 77.0783 4.36514 77.3011 4.1383C77.5234 3.90673 77.6969 3.63227 77.8111 3.33143C77.9253 3.03059 77.9779 2.70959 77.9657 2.38776C77.9801 2.0699 77.9285 1.75252 77.8141 1.45591C77.6997 1.1593 77.525 0.890003 77.3013 0.665261C77.0776 0.440519 76.8098 0.265284 76.515 0.150759C76.2202 0.0362338 75.9049 -0.0150547 75.5892 0.000180388Z" fill="white"/>
              <path d="M16.6405 14.0259V20.4927C16.6398 20.6586 16.6066 20.8228 16.5429 20.9758C16.4792 21.1288 16.3862 21.2677 16.2692 21.3845C16.1522 21.5013 16.0135 21.5938 15.861 21.6566C15.7085 21.7195 15.5452 21.7515 15.3805 21.7508H10.6914C10.7685 21.9967 10.8079 22.253 10.8083 22.5109C10.8016 23.1239 10.5719 23.7131 10.1627 24.167C9.75357 24.621 9.19333 24.9081 8.58808 24.974C7.98282 25.04 7.37457 24.8802 6.87844 24.5249C6.38231 24.1697 6.03273 23.6436 5.89592 23.0463C5.79689 22.6172 5.81712 22.169 5.95439 21.7508H1.25999C1.09523 21.7515 0.931918 21.7195 0.779425 21.6566C0.626932 21.5938 0.488219 21.5013 0.371217 21.3845C0.254215 21.2677 0.16124 21.1288 0.0975421 20.9758C0.033844 20.8228 0.000697104 20.6586 4.16522e-07 20.4927V14.0259C-0.000170327 13.8885 0.0521592 13.7562 0.146183 13.6565C0.240207 13.5569 0.368693 13.4975 0.505036 13.4906H6.0554C5.9358 13.1576 5.8901 12.8022 5.92149 12.4495C5.95288 12.0967 6.06062 11.7552 6.23712 11.4489C6.41362 11.1426 6.65461 10.8789 6.94314 10.6764C7.23168 10.4739 7.56074 10.3375 7.90723 10.2768C8.25373 10.216 8.60924 10.2325 8.94875 10.3249C9.28826 10.4173 9.60354 10.5835 9.87244 10.8117C10.1413 11.04 10.3574 11.3248 10.5053 11.6461C10.6532 11.9674 10.7294 12.3174 10.7286 12.6715C10.7308 12.9511 10.6821 13.2287 10.585 13.4906H16.1354C16.2064 13.4859 16.2776 13.4965 16.3443 13.5217C16.4109 13.5469 16.4714 13.5862 16.5218 13.6369C16.5721 13.6876 16.6111 13.7485 16.6361 13.8156C16.6612 13.8827 16.6717 13.9544 16.667 14.0259" fill="white"/>
              <path d="M16.6403 7.34514V11.7295C16.6426 11.7634 16.6426 11.7974 16.6403 11.8312H12.3339C12.1246 10.9244 11.6166 10.1157 10.8926 9.5367C10.1685 8.95771 9.27118 8.64254 8.34661 8.64254C7.42204 8.64254 6.52471 8.95771 5.80069 9.5367C5.07668 10.1157 4.56863 10.9244 4.35928 11.8312H0.0476517C0.045314 11.7974 0.045314 11.7634 0.0476517 11.7295V7.34514C0.0454462 7.13351 0.0958609 6.92468 0.194289 6.73768C0.292716 6.55068 0.436028 6.39145 0.611193 6.27447C2.96106 4.7006 5.32158 3.12673 7.67144 1.5475C7.87897 1.40981 8.12203 1.33643 8.37056 1.33643C8.61908 1.33643 8.86215 1.40981 9.06967 1.5475L16.1299 6.27447C16.2995 6.39477 16.4368 6.55553 16.5296 6.74238C16.6224 6.92923 16.6677 7.13633 16.6615 7.34514" fill="white"/>
              </g>
              <defs>
              <clipPath id="clip0_4_519">
              <rect width="94" height="25" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </div>
      
          <div className="site-map">
            <h5 style={{color:'#fff', fontWeight: 300}} >Mapa do site</h5>
            <ul className="nav flex-column" style={{gap: '10px', fontSize: '13px'}}>
              <li className="nav-item mb-2"><a href="/" className="nav-link p-0 colorlink">Explorar</a></li>
              <li className="nav-item mb-2"><a href="/" className="nav-link p-0 colorlink">Simular compra/venda</a></li>
              <li className="nav-item mb-2"><a href= {environment.appUrl+'/login'} className="nav-link p-0 colorlink">Login</a></li>
            </ul>
          </div>
      
          <div className="register">
            <h5 style={{color:'#fff', fontWeight: 300}} >Cadastre-se</h5>
            <ul className="nav flex-column" style={{gap: '10px', fontSize: '13px'}}>
              <li className="nav-item mb-2"><a href={environment.appUrl+'/login'}  className="nav-link p-0 colorlink">Sou comprador</a></li>
              <li className="nav-item mb-2"><a href={environment.appUrl+'/login'}  className="nav-link p-0 colorlink">Sou proprietário</a></li>
              <li className="nav-item mb-2"><a href={environment.appUrl+'/login'}  className="nav-link p-0 colorlink">Sou corretor</a></li>
              <li className="nav-item mb-2"><a href={environment.appUrl+'/login'}  className="nav-link p-0 colorlink">Sou fornecedor</a></li>
            </ul>
          </div>
      
          <div className="contact">
            <h5 style={{color:'#fff', fontWeight: 300}} >Fale conosco</h5>
            <ul className="nav flex-column" style={{gap: '10px', fontSize: '13px'}}>
              <li className="nav-item mb-2"><a href="#" className="nav-link p-0 colorlink">truliv@email.com</a></li>
              <li className="nav-item mb-2"><a href="#" className="nav-link p-0 colorlink">11 98765-4321</a></li>
            </ul>
          </div>
      
          <div className="social">
            <h5 style={{color:'#fff', fontWeight: 300}} >Siga nas redes</h5>
            <div className="container-social-icons">
              <div>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z" stroke="#ECEBBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M16 11.3698C16.1234 12.2021 15.9812 13.052 15.5937 13.7988C15.2062 14.5456 14.5931 15.1512 13.8416 15.5295C13.0901 15.9077 12.2384 16.0394 11.4077 15.9057C10.5771 15.7721 9.80971 15.3799 9.21479 14.785C8.61987 14.1901 8.22768 13.4227 8.09402 12.592C7.96035 11.7614 8.09202 10.9097 8.47028 10.1582C8.84854 9.40667 9.45414 8.79355 10.2009 8.40605C10.9477 8.01856 11.7977 7.8764 12.63 7.99981C13.4789 8.1257 14.2648 8.52128 14.8716 9.12812C15.4785 9.73496 15.8741 10.5209 16 11.3698Z" stroke="#ECEBBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M17.5 6.5H17.51" stroke="#ECEBBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <div>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.54 6.42C22.4212 5.94541 22.1792 5.51057 21.8386 5.15941C21.498 4.80824 21.0707 4.55318 20.6 4.42C18.88 4 12 4 12 4C12 4 5.11996 4 3.39996 4.46C2.92921 4.59318 2.50194 4.84824 2.16131 5.19941C1.82068 5.55057 1.57875 5.98541 1.45996 6.46C1.14518 8.20556 0.991197 9.97631 0.999961 11.75C0.988741 13.537 1.14273 15.3213 1.45996 17.08C1.59092 17.5398 1.83827 17.9581 2.17811 18.2945C2.51794 18.6308 2.93878 18.8738 3.39996 19C5.11996 19.46 12 19.46 12 19.46C12 19.46 18.88 19.46 20.6 19C21.0707 18.8668 21.498 18.6118 21.8386 18.2606C22.1792 17.9094 22.4212 17.4746 22.54 17C22.8523 15.2676 23.0063 13.5103 23 11.75C23.0112 9.96295 22.8572 8.1787 22.54 6.42V6.42Z" stroke="#ECEBBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9.75 15.02L15.5 11.75L9.75 8.47998V15.02Z" stroke="#ECEBBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <div>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 2H15C13.6739 2 12.4021 2.52678 11.4645 3.46447C10.5268 4.40215 10 5.67392 10 7V10H7V14H10V22H14V14H17L18 10H14V7C14 6.73478 14.1054 6.48043 14.2929 6.29289C14.4804 6.10536 14.7348 6 15 6H18V2Z" stroke="#ECEBBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </footer>
      

    )
}
export default AlternativeFooter;
