import React from 'react';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Routes from './Routes';
import StoreProvider  from './providers/provides';
import './App.scss';

const App = () => {
  return (
    <>
      <Header />
      <div>
        <StoreProvider>
          <Routes/>
        </StoreProvider>
      </div>
      {/* <Footer /> */}
    </>
  )
}

export default App;