import axios from 'axios';
import environment from '../environment';

const api = axios.create({ baseURL: environment.apiUrl });

class Service {

  getURLBase() {
  }

  api() {
    return api;
  }

  async get(url: string) {
    return await api.get(this.getURLBase() + url).then((response: any) => response.data);
  }

  async post(url: string, obj: any) {
    return await api.post(this.getURLBase() + url, obj).then((response: any) => response);
  }

  put(url: string, obj: any) {
    return api.put(this.getURLBase() + url, obj).then((response: any) => response);
  }

  getAllPager(page: number, perPage: number) {
    return api.get(this.getURLBase() + 'pager/' + page + '/' + perPage).then((response: any) => response.data);;
  }

  getAllPagerSearch(page: number, perPage: number, search: string) {
    return api.get(this.getURLBase() + 'pager/search/' + page + '/' + perPage + '/' + search).then((response: any) => response.data);;
  }

  getAll() {
    return api.get(this.getURLBase() + 'list').then((response: any) => response.data);;
  }

  count() {
    return api.get(this.getURLBase() + 'count').then((response: any) => response.data);;
  }

  getById(id: string) {
    return api.get(this.getURLBase() + id).then((response: any) => response.data);;
  }

  create(obj: any) {
    return api.post(this.getURLBase() + 'create', obj).then((response: any) => response.data);;
  }

  update(obj: any) {
    return api.put(this.getURLBase() + 'update', obj).then((response: any) => response.data);;
  }

  delete(id: string) {
    return api.delete(this.getURLBase() + 'delete/' + id).then((response: any) => response.data);;
  }

  handleError(error: any) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log('Error', error.message);
    }
    console.log(error.config);
    return Promise.reject(error.response.data.message);
  }
}

export default Service;
