import * as React from 'react';
import LogoTopo from '../../assets/truliv-logo.jpeg';
import './Header.scss';
import environment from '../../environment';

const Header = () => {
  return(
    <>
      <div className="center-header">
        <div className="container-header">
          <div className="logo">
            <a href="/" >
              <svg width="120" height="32" viewBox="0 0 120 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_20_2114)">
                  <path d="M42.7781 22.681C42.5148 22.4206 42.3105 22.1056 42.1793 21.7579C42.0481 21.4101 41.993 21.0378 42.0179 20.6665V13.129H46.5109V8.68188H41.8619V4.07031H37.2671V6.81121C37.2671 7.05655 37.2191 7.29947 37.1259 7.52605C37.0327 7.75262 36.8962 7.9584 36.724 8.13156C36.5519 8.30472 36.3475 8.44187 36.1228 8.53513C35.898 8.62839 35.6573 8.67593 35.4143 8.67502H33.3782V13.1153H37.1178V20.6253C37.1178 22.882 37.7693 24.6179 39.0724 25.8331C40.4765 27.0936 42.3129 27.7501 44.1898 27.6626H46.9928V23.3662H45.0789C44.2565 23.433 43.4389 23.1888 42.7849 22.681" fill="#155859"/>
                  <path d="M56.9696 9.09298C56.195 9.44024 55.5404 10.0128 55.0896 10.7375V8.68184H50.1895V27.6214H55.0896V17.0827C55.0564 16.5785 55.1396 16.0734 55.3328 15.6073C55.526 15.1412 55.8238 14.7267 56.2027 14.3966C56.6389 13.9633 57.1621 13.6296 57.737 13.418C58.3119 13.2064 58.9251 13.1219 59.5351 13.1701H61.5712V8.53793H59.8541C58.873 8.51023 57.8977 8.6997 56.9968 9.09298" fill="#155859"/>
                  <path d="M76.4888 17.9804C76.4929 18.822 76.3386 19.6568 76.0341 20.4403C75.7461 21.1963 75.2606 21.8594 74.6292 22.359C73.9982 22.8816 73.198 23.1501 72.3827 23.1127C71.9139 23.1469 71.4436 23.0705 71.009 22.8898C70.5744 22.709 70.1873 22.4288 69.8783 22.0712C69.2615 21.3416 68.9371 20.4055 68.9689 19.4468V8.68872H64.0687V20.7898C63.9684 22.7124 64.6163 24.5984 65.8741 26.0455C67.0731 27.352 68.7789 28.0052 70.9914 28.0052C72.1617 28.0696 73.3291 27.8339 74.3849 27.32C75.301 26.7953 76.0713 26.0459 76.6246 25.141V27.6558H81.4161V8.68872H76.516L76.4888 17.9804Z" fill="#155859"/>
                  <path d="M90.1917 1.50073H85.2915V27.6284H90.1917V1.50073Z" fill="#155859"/>
                  <path d="M98.9129 8.68188H94.0127V27.6284H98.9129V8.68188Z" fill="#155859"/>
                  <path d="M114.428 8.72986L111.306 23.5718H110.098L106.969 8.72986H101.404L107.064 27.6284H108.476H110.451H110.946H112.738H114.34L120 8.72986H114.428Z" fill="#155859"/>
                  <path d="M96.4967 5.99984e-05C96.0872 -0.0160878 95.6786 0.0507955 95.2951 0.196732C94.9116 0.342668 94.5609 0.564712 94.2638 0.849753C93.9762 1.13691 93.7511 1.48141 93.6027 1.8613C93.4543 2.24119 93.3859 2.64811 93.4019 3.05616C93.3834 3.47082 93.451 3.8848 93.6002 4.27159C93.7493 4.65838 93.9769 5.00943 94.2682 5.30229C94.5596 5.59515 94.9083 5.82336 95.292 5.97227C95.6758 6.12119 96.0861 6.18754 96.4967 6.16708C96.9009 6.18324 97.304 6.1142 97.6802 5.96437C98.0565 5.81454 98.3977 5.58721 98.6821 5.29685C98.9659 5.00045 99.1874 4.64913 99.3332 4.26406C99.479 3.87899 99.5461 3.4681 99.5305 3.05616C99.5489 2.6493 99.483 2.24305 99.337 1.86339C99.1909 1.48373 98.968 1.13903 98.6824 0.851363C98.3968 0.563693 98.0549 0.339392 97.6786 0.1928C97.3022 0.0462083 96.8997 -0.0194409 96.4967 5.99984e-05Z" fill="#155859"/>
                  <path d="M21.2431 17.9529V26.2304C21.2422 26.4428 21.1999 26.6529 21.1186 26.8488C21.0373 27.0446 20.9186 27.2224 20.7692 27.3719C20.6198 27.5215 20.4428 27.6398 20.2481 27.7203C20.0534 27.8007 19.845 27.8416 19.6346 27.8407H13.6485C13.747 28.1555 13.7973 28.4836 13.7978 28.8137C13.7892 29.5984 13.496 30.3525 12.9737 30.9336C12.4514 31.5146 11.7362 31.8821 10.9635 31.9665C10.1908 32.0509 9.41434 31.8464 8.78099 31.3917C8.14763 30.9369 7.70136 30.2636 7.5267 29.499C7.40029 28.9498 7.42611 28.3761 7.60135 27.8407H1.6085C1.39816 27.8416 1.18968 27.8007 0.995011 27.7203C0.800339 27.6398 0.623258 27.5215 0.473894 27.3719C0.32453 27.2224 0.205839 27.0446 0.124522 26.8488C0.0432051 26.6529 0.00088992 26.4428 5.3173e-07 26.2304V17.9529C-0.000217439 17.777 0.0665862 17.6077 0.186617 17.4801C0.306647 17.3526 0.470672 17.2765 0.644727 17.2677H7.7303C7.57762 16.8414 7.51927 16.3866 7.55934 15.9351C7.59942 15.4836 7.73696 15.0464 7.96228 14.6543C8.1876 14.2622 8.49525 13.9247 8.86359 13.6655C9.23193 13.4064 9.65201 13.2317 10.0943 13.154C10.5367 13.0763 10.9905 13.0973 11.4239 13.2156C11.8574 13.3339 12.2598 13.5466 12.6031 13.8388C12.9464 14.1309 13.2222 14.4954 13.411 14.9067C13.5998 15.318 13.6971 15.766 13.6961 16.2193C13.6988 16.5771 13.6367 16.9325 13.5128 17.2677H20.5984C20.689 17.2616 20.78 17.2752 20.865 17.3075C20.9501 17.3398 21.0273 17.3901 21.0916 17.4549C21.1559 17.5198 21.2056 17.5978 21.2376 17.6837C21.2696 17.7696 21.2831 17.8614 21.2771 17.9529" fill="#155859"/>
                  <path d="M21.2432 9.40136V15.0133C21.2461 15.0567 21.2461 15.1002 21.2432 15.1435H15.7457C15.4784 13.9828 14.8299 12.9477 13.9056 12.2066C12.9814 11.4654 11.8358 11.062 10.6555 11.062C9.47519 11.062 8.32966 11.4654 7.40539 12.2066C6.48111 12.9477 5.83254 13.9828 5.56528 15.1435H0.0610761C0.0580918 15.1002 0.0580918 15.0567 0.0610761 15.0133V9.40136C0.0582605 9.13047 0.12262 8.86317 0.248272 8.62381C0.373925 8.38445 0.556876 8.18063 0.780491 8.03091C3.78032 6.01635 6.79375 4.00179 9.79358 1.98038C10.0585 1.80414 10.3688 1.71021 10.6861 1.71021C11.0033 1.71021 11.3136 1.80414 11.5785 1.98038L20.5916 8.03091C20.8081 8.18488 20.9835 8.39066 21.1019 8.62983C21.2203 8.869 21.2782 9.13408 21.2703 9.40136" fill="#155859"/>
                </g>
                <defs>
                  <clipPath id="clip0_20_2114">
                    <rect width="120" height="32" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

            </a>
          </div>
          <div className="menu-links">
            <ul>
              <li><a href= {environment.appUrl+'/home'} className="nav-link ">Explorar</a></li>
              <li><a href="/" className="nav-link activad">Simular compra/venda</a></li>
              <li><a href= {environment.appUrl+'/register'} className="nav-link">Quero comprar</a></li>
              <li><a href= {environment.appUrl+'/register'} className="nav-link">Quero vender</a></li>
            </ul>
          </div>

          <div className="btn-login pull-right">
            <a href= {environment.appUrl+'/login'} className="btn topbar-btn-login" style={{ width: '100%'}}>Login</a>
          </div>

          <div className="btn-login-mobile pull-right">
            {/* <div style={{marginRight: '20px'}}>
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 19.5C15.4183 19.5 19 15.9183 19 11.5C19 7.08172 15.4183 3.5 11 3.5C6.58172 3.5 3 7.08172 3 11.5C3 15.9183 6.58172 19.5 11 19.5Z" stroke="#155859" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M21.0004 21.5L16.6504 17.15" stroke="#155859" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            <div>
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 12.5H21" stroke="#155859" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3 6.5H21" stroke="#155859" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3 18.5H21" stroke="#155859" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div> */}

            <nav>
                <input type="checkbox" id="check" style={{display: 'none'}} />
              <label htmlFor="check"  className="checkbtn">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 12.5H21" stroke="#155859" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M3 6.5H21" stroke="#155859" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M3 18.5H21" stroke="#155859" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </label>
              <ul>
                <li><hr/></li>
                <li><a  href= {environment.appUrl+'/login'} className="nav-link">Login</a></li>
                <li><hr/></li>
                <li><a href= {environment.appUrl+'/home'} className="nav-link">Explorar</a></li>
                <li><a href="/" className="nav-link activad"  >Simular compra/venda</a></li>
                <li><a href= {environment.appUrl+'/register'} className="nav-link">Quero comprar</a></li>
                <li><a href= {environment.appUrl+'/register'}className="nav-link">Quero vender</a></li>
              </ul>
            </nav>
          </div>
        </div>

      </div>
      
      {/* <div className='tudo-header'>
          
          <img className='logo' alt="truliv.com.br" src={ LogoTopo }/>
          
      </div> */}
    </>

  )
}
export default Header;