import Service from "./services";

export class ModelService extends Service {

  getURLBase() {
    return 'p/model/';
  }

  getAllStates() {
    return super.get('list/states');
  }

  getAllCitiesByState(state: string) {
    return super.get(`list/cities?state=${state}`);
  }

  getAllNeighborhoodsByCity(state: string, city: string) {
    return super.get(`list/neighborhoods?state=${state}&city=${city}`);
  }

}
