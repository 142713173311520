import React, { useContext, useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useForm } from '../../hooks/useForms';
import { GlobalContext } from '../../providers/provides';
import { ModelService } from '../../services/model';
import CurrencyInput from '../../components/CurrencyInput';
import { ToastContainer,toast } from 'react-toastify';
import './EvaluationPage.scss';
import { Value } from 'sass';

const modelService: ModelService = new ModelService();

export default function EvaluationPage(props: any) {

  const initialState = {
    goal: "",
    state: "",
    city: "",
    neighborhood: "",
    rooms: 0,
    bathroom: 0,
    suites: 0,
    garages: 0,
    usefulArea: "100",
    condoPrice: "",
    type: ""
  }

  const types = ["Apartamento"]

  const { valuationState, setDataEtapa, setValuationState } = useContext(GlobalContext);
  const { onChange, onSubmit, values } = useForm(valuationCallback, initialState);

  const notify = () => toast.warn('Não é permitido valores negativos, infome um valor válido para o cômodo!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  

  // SETAR VALOR PARA CONSTRUIR O VALUANTION
  async function valuationCallback() {
    setValuationState((prevState: any) => ({
      goal: goal,
      state: values.state,
      city: values.city,
      neighborhood: values.neighborhood,
      rooms: rooms,
      bathroom: bathroom,
      suites: suites,
      garages: garages,
      usefulArea: values.usefulArea,
      condoPrice: condoPrice,
      type: values.type,
    }));
    // SETAR VALOR PARA CONSTRUIR A DATA DE ETAPA
    setDataEtapa((prevState: any) => ({
      ...prevState,
      stepOne: 'yes'
    }));
    props.history.push(`/1`);
  }

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);

  const [rooms, setRooms] = useState(1);
  const [bathroom, setBathoom] = useState(1);
  const [suites, setSuites] = useState(0);
  const [garages, setGarages] = useState(0);
  const [goal, setGoal] = useState<string>();
  const [usefulArea, setUsefulArea] = useState<number>();

  const [errorMessage, setErrorMessage] = useState('');
  const [className, setClassName] = useState('');
  const [condoPrice, setCondoPrice] = useState<string | undefined>(' ');


  useEffect(() => {
    async function fectchData() {
      setStates(await modelService.getAllStates());
    }
    fectchData()
  }, []);

  // pegar dados do cliente ja selecionados
  useEffect(() => {
    if (valuationState.state) values.state = valuationState.state
    if (valuationState.neighborhood) values.neighborhood = valuationState.neighborhood
    if (valuationState.city) values.city = valuationState.city
    if (valuationState.garages) values.garages = garages
    if (valuationState.rooms) values.rooms = rooms
    if (valuationState.bathroom) values.bathroom = bathroom
    if (valuationState.suites) values.suites = suites
    if (valuationState.goal) values.goal = goal
    if (valuationState.type) values.type = valuationState.type
    if (valuationState.usefulArea) values.usefulArea = valuationState.usefulArea
    if (valuationState.condoPrice) values.condoPrice = condoPrice

  }, [bathroom, condoPrice, garages, goal, rooms, suites, valuationState.bathroom, valuationState.city, valuationState.condoPrice, valuationState.garages, valuationState.goal, valuationState.neighborhood, valuationState.rooms, valuationState.state, valuationState.suites, valuationState.type, valuationState.usefulArea, values])

  // CHAMAR A FUNÇÃO CASO O USUARIO JA TENHA COLOCADO OS VALORES E TENHA VOLTADO PARA A ETAPA1
  useEffect(() => {
    if (valuationState.state) getCities(valuationState.state)
    if (valuationState.city) getNeighborhood(valuationState.city)
    if (valuationState.garages) setGarages(valuationState.garages)
    if (valuationState.rooms) setRooms(valuationState.rooms)
    if (valuationState.bathroom) setBathoom(valuationState.bathroom)
    if (valuationState.suites) setSuites(valuationState.suites)
    if (valuationState.goal) setGoal(valuationState.goal)
    if (valuationState.type) initialState.type = valuationState.type
    if (valuationState.condoPrice) setCondoPrice(valuationState.condoPrice);
    if (valuationState.usefulArea) setUsefulArea(valuationState.usefulArea)

  }, [valuationState.bathroom, valuationState.city, valuationState.condoPrice, valuationState.garages, valuationState.goal, valuationState.rooms, valuationState.state, valuationState.suites, valuationState.type, valuationState.usefulArea])

  const getCities = async (event: any) => {
    if (valuationState.state) {
      setCities(await modelService.getAllCitiesByState(event));
    } else {
      setCities(await modelService.getAllCitiesByState(event.target.value));
    }
  }

  const getNeighborhood = async (event: any) => {
    const { state }: any = values;
    if (valuationState.city) {
      setNeighborhoods(await modelService.getAllNeighborhoodsByCity(valuationState.state, event));
    } else {
      setNeighborhoods(await modelService.getAllNeighborhoodsByCity(state, event.target.value));
    }
  };

   const validateValue = (value: string | undefined): void => {
    const condoValue = value === undefined ? 'undefined' : value;
    setCondoPrice(condoValue || ' ');
    
    if (!value) {
      setClassName('');
    } else {
      setClassName('is-valid');
    }
  };

  return (
    <>
      <div className='container-flex'>
        <div className='contents'>
          <div className='container-progress'>
            <div className="progress">
              <ProgressBar now={33} />
            </div>
            <span>1/3</span>
          </div>


          <div className='header'>
            <span>Avalie seu imóvel</span> 
            <p style={{fontSize:'16px'}}>Pensando em comprar ou vender um apartamento em São Paulo? A inteligência artificial da Truliv lhe ajuda a descobrir quanto vale o seu imóvel, totalmente grátis.</p>
          </div>

          <div className='button-options'>
            <ul className="nav nav-pills">
              <li className="nav-item">
                
                <a href="" className={goal === 'SELL' ? "nav-link  inactiveleft active" : "nav-link  inactiveleft"} onClick={() => setGoal('SELL')} data-toggle="tab" aria-expanded="false">
                  Venda
                </a>
              </li>
              <li className="nav-item">
                
                <a href="" className={goal === 'BUY' ? "nav-link  inactiveleft active" : "nav-link  inactiveleft"} onClick={() => setGoal('BUY')} data-toggle="tab" aria-expanded="false">
                  Compra
                </a>
              </li>
            </ul>
          </div>

          <div>
            <span className='sub-title-avaliacao'>Local e tipo</span>
            <ToastContainer />
            <form onSubmit={onSubmit} className="form-Evaluation">
              <div className='input-select'>
                <label>UF</label>
                <select defaultValue="" className="custom-select" id="state" name="state" required onChange={(event: any) => { onChange(event); getCities(event); }}>
                  <option value="" >Escolha o Estado...</option>
                  {
                    states.map((item: any, index: number) => (
                      <option selected={valuationState.state === item.state} key={`${item.state}_${index}`} value={item.state}>{item.state}</option>
                    ))
                  }
                </select>
              </div>

              <div className='input-select'>
                <label>Cidade</label>
                <select defaultValue="" className="custom-select" id="city" name="city" required onChange={(event: any) => { onChange(event); getNeighborhood(event) }}>
                  <option value="">Escolha a Cidade...</option>
                  {
                    cities.map((item: any, index: number) => (
                      <option selected={valuationState.city === item.city} key={`${item.city}_${index}`} value={item.city}>{item.city}</option>
                    ))
                  }
                </select>
              </div>

              <div className='input-select'>
                <label >Bairro</label>
                <select defaultValue="" className="custom-select" id="neighborhood" name="neighborhood" required onChange={(value: any) => onChange(value)}>
                  <option value="">Escolha o Bairro...</option>
                  {
                    neighborhoods.map((item: any, index: number) => (
                      <option selected={valuationState.neighborhood === item.neighborhood} key={`${item.neighborhood}_${index}`} value={item.neighborhood}>{item.neighborhood}</option>
                    ))
                  }
                </select>
              </div>

              <div className='input-select'>
                <label>Tipo de Imóvel</label>
                <select defaultValue="" className="custom-select" id="type" name="type" required onChange={(value: any) => onChange(value)}>
                  <option value="">Escolha o Tipo de Imóvel...</option>
                  {
                    types.map((item: any, index: number) => (
                      <option selected={item === valuationState.type} key={`${item}_${index}`} value={item}>{item}</option>
                    ))
                  }
                </select>
              </div>
              <div style={{ marginTop: '40px', width: '100%' }}>
                <span className='sub-title-avaliacao'>Características</span>
              </div>

              <div className='input-stepper'>
                <label>Quartos <span>(sem banheiro)</span></label>
                <div className="stepper">
                  <span className="stepper__button" onClick={() => {if(rooms <= 0){setRooms(1); return notify();} setRooms(rooms - 1) }} >-</span>

                  <div className="stepper__content">
                    <input type="number" id="rooms" name="rooms" required className="stepper__input"   value={rooms} />
                  </div>
                  <span className="stepper__button" onClick={() => setRooms(rooms + 1)}>+</span>
                </div>
              </div>

              <div className='input-stepper'>
                <label>Suítes</label>
                <div className="stepper">
                  <span className="stepper__button" onClick={() => {if(suites <= 0){setSuites(0); return notify();} setSuites(suites - 1)}}>-</span>

                  <div className="stepper__content">
                    <input type="text" id="suites" name="suites" required className="stepper__input" value={suites} />
                  </div>
                  <span className="stepper__button" onClick={() => setSuites(suites + 1)} >+</span>
                </div>
              </div>

              <div className='input-stepper'>
                <label>Banheiros</label>
                <div className="stepper">
                  <span className="stepper__button" onClick={() => {if(bathroom <= 0){setBathoom(1); return notify();}setBathoom(bathroom - 1)}}>-</span>

                  <div className="stepper__content">
                    <input type="text" id="bathroom" name="bathroom" required className="stepper__input" value={bathroom} />
                  </div>
                  <span className="stepper__button" onClick={() => setBathoom(bathroom + 1)}>+</span>
                </div>
              </div>

              <div className='input-stepper'>
                <label>Vagas</label>
                <div className="stepper">
                  <span className="stepper__button" onClick={() =>  {if(garages <= 0){setGarages(0); return notify();}setGarages(garages - 1)}}>-</span>

                  <div className="stepper__content">
                    <input type="text" id="garages" name="garages" required className="stepper__input" value={garages} />
                  </div>
                  <span className="stepper__button" onClick={() => setGarages(garages + 1)}>+</span>
                </div>
              </div>

              <div style={{ marginTop: '40px', width: '100%' }}>
                <span className='sub-title-avaliacao'>Área útil e Condomínio</span>
              </div>

              <div className='input-select'>
                <label>Área útil (m²)</label>
                <input type="number" id="usefulArea" placeholder='0' defaultValue={usefulArea} name="usefulArea" className="form-control" required onChange={onChange} />
              </div>

              <div className='input-select'>
                <label>Valor do Condomínio (R$)</label>
                  <CurrencyInput
                    id="condoPrice"
                    name="condoPrice"
                    placeholder="R$ "
                    allowDecimals={true}
                    defaultValue={condoPrice}
                    className={`form-control ${className}`}
                    onValueChange={validateValue}
                    prefix={'R$ '}
                    step={10}
                  />
              </div>


              <div className='footer-button'>
                <div className='container-footer-action'>
                  <Link to={{
                    pathname: '/2',
                    state: {
                      fromNotifications: true
                    }
                  }}></Link>
                  {/* Se o botão submit sair do form não tem submit do form referente */}
                  <button type="submit">Próximo</button>
                </div>
              </div>

            </form>
          </div>
        </div>
        {/* FOOTER por hora para uma solução rapida */}
        {/* <div className='footer-button' style={{ position: 'absolute', bottom: '0px', zIndex: '-1' }}></div> */}
      </div >
    </>
  )
}