import React, { createContext, useState } from 'react';

export const GlobalContext = createContext();

export default function StoreProvider ({ children }){
    const [valuationState, setValuationState] = useState({});
    const [dataEtapa, setDataEtapa] = useState({
        stepOne: 'no',
        stepTwo: 'no',
        stepThree: 'no',
        stepFour: 'no',
        stepFive: 'no',
    });

   return (
    <GlobalContext.Provider
        value={{
            valuationState,
            setValuationState,
            dataEtapa,
            setDataEtapa
        }}
    >
        {children}
    </GlobalContext.Provider>
   )
}