export class UtilHelper {

  public calculateM2 = (value: number, usefulArea: number) => {
    return (value / usefulArea).toFixed(2);
  }

  public formatToBRL = (value: number) => {
    
    return (value.toLocaleString('pt-br', { style: 'decimal', currency: 'BRL' }));
  }

  public getCurrentDate() {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${date}/${month < 10 ? `0${month}` : `${month}`}/${year}`;
    // return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
  }


}